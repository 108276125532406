import { Component, OnInit, Inject } from '@angular/core';
import {MatIcon, MatDialog, MatTableDataSource, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {COPQMDR} from '../../../models/copq__mdr';
import {TranslateService} from '@ngx-translate/core';
import {PriceObservable} from '../../../services/price.service';
import {MdrService} from '../../../providers/mdr.service';
import {MdrObservable} from '../../../services/mdr.service';
import {UOCObservable} from '../../../services/uoc.service';
import {ActivatedRoute} from '@angular/router';
import {MDR, MDR_STATUS} from '../../../models/mdr';
import {Transport} from '../../../models/transport';
import {Copq} from '../../../models/copq';
import {CostAvoidance} from '../../../models/costAvoidance';
import {UOC} from '../../../models/uoc';
import {UOCMDR} from '../../../models/uoc__mdr';
import {CostAvoidanceMDR} from '../../../models/costAvoidance__mdr';
import {Price} from '../logistic-order.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ExcelService } from '../../../misc/export-xlsx/export-xlsx';
import * as XLSX from 'xlsx';
import { PriceMakerService } from '../../../providers/price-maker.service';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class TaxComponent implements OnInit {

  public static readonly CAPITAL_CITIES = [
    'Arujá', 'Barueri', 'Biritiba-Mirim', 'Caieiras', 'Cajamar', 'Carapicuíba', 'Cotia', 'Diadema', 'Embu', 'Embu-Guaçu', 'Ferraz de Vasconcelos', 'Francisco Morato', 'Franco da Rocha', 'Guarulhos', 'Itapecerica da Serra', 'Itapevi',
    'Itaquaquecetuba', 'Jandira', 'Mairiporã', 'Mauá', 'Mogi das Cruzes', 'Osasco', 'Pirapora do Bom Jesus', 'Poá', 'Ribeirão Pires', 'Rio Grande da Serra', 'Salesópolis', 'Santa Isabel', 'Santana do Parnaíba', 'Santo André',
    'São Bernardo do Campo', 'São Caetano do Sul', 'São Lourenço da Serra', 'São Paulo', 'Suzano', 'Taboão da Serra', 'Vargem Grande Paulista', 'Capital'
  ];

  constructor(public translate: TranslateService,
              public snackBar: MatSnackBar,
              public priceObservable: PriceObservable,
              public mdrService: MdrService,
              public mdrObservable: MdrObservable,
              public uocObservable: UOCObservable,
              public aRoute: ActivatedRoute,
              public excel: ExcelService,
              private dpipe: DatePipe,
              private cpipe: CurrencyPipe,
              private priceMaker: PriceMakerService,
              public dialog: MatDialog) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.user.role;
    this.user = this.currentUser.user.id;
  }

  subscriptions: any[] = [];

  statusContainer = false;
  mdr: MDR = new MDR;
  role: any;
  currentUser: any;
  sumCOPQ = 0;
  sumAvoid = 0;
  sumUOC = 0;
  region = null;
  sumTotal = 0;
  COPQs: COPQMDR[] = [];
  costAvoidances: CostAvoidanceMDR[] = [];
  UOCs: UOCMDR[] = [];
  uoc: UOC[] = [];
  copq: UOC[] = [];
  price: any = { price: 0, taxedPrice: 0, adValorem: 0, total: 0};
  user: any;
  nfePrice: number;
  ov: any = [];
  MDR_STATUS = MDR_STATUS;

  editing_freight = false;
  update = true;
  payment = {
    'tooltips': {
            'green': 'Pagamento Realizado',
            'yellow': 'Pagamento Parcial Realizado',
            'red': 'Aguardando Pagamento',
            'undefined': '',
    },
    'icons' : {
            'green':  'fa-check',
            'yellow':    'fa-exclamation-triangle',
            'red':    'fa-exclamation-triangle',
            'undefined': '',
    },
    'classes' : {
            'green':  'icon-green',
            'yellow': 'icon-yellow',
            'red':    'icon-red',
            'undefined': '',
    }
  };
  payField = {
    'color': 'undefined',
    'ov': []
  };

  // fiscal in/out state
  icmsState = {
    in: {
      all: [],
      editing: false,
    },
    out: {
      all: [],
      editing: false,
    }
  };
  calculationsShouldBeShown: any;

  static UFtoRegion(mdr: MDR): string {
    // console.log(uf);
    switch (mdr.fiscalOutState) {
      case 'AC':
      case 'AM':
      case 'RO':
      case 'RR':
      case 'PA':
      case 'AP':
      case 'TO':
        return 'Norte';
      case 'SP': {
        return 'Estado de SP';
          // if (this.CAPITAL_CITIES.includes(mdr.drList[mdr.drList.length - 1].city)) {
          //   return 'São Paulo Interior';
          // } else {
          //   return 'São Paulo Capital';
          // }
        }
      case 'MA':
      case 'PI':
      case 'CE':
      case 'RN':
      case 'PB':
      case 'PE':
      case 'AL':
      case 'SE':
      case 'BA':
        return 'Nordeste';
      case 'MT':
      case 'MS':
      case 'GO':
      case 'DF':
        return 'Centro Oeste';
      case 'MG':
      case 'ES':
      case 'RJ':
        return 'Sudeste (S/ SP)';
      case 'PR':
      case 'SC':
      case 'RS':
        return 'Sul';
      default:
        return 'Sudeste (S/ SP)';
    }
  }

  ngOnInit() {
    // pega in/out states
    this.priceMaker.getIcmsStates().subscribe((response) => {
      this.icmsState.in.all = response.in;
      this.icmsState.out.all = response.out;
    });
    // pega price
    this.priceObservable.currentPrice.subscribe((price) => {
      this.price = price;
    });
    // quando a MDR mudar
    this.mdrObservable.currentMDR.subscribe(mdr => {
      this.mdr = mdr;
      this.sumTotal = mdr.valorem + mdr.frete + mdr.additional;
      // pega os CoPQs da MDR
      this.mdrService.getCoPQ(this.mdr).subscribe((data) => {
        this.sumCOPQ = 0;
        data.forEach((elem) => {
          elem.value = Number(elem.value) / 100;
          this.sumCOPQ += elem.value;
          elem.value = elem.value.toFixed(2);
        });
        // this.sumCOPQ = this.sumCOPQ / 100;
        this.COPQs = data;
      });
      // pega os CostAvoidances da MDR
      this.mdrService.getCostAvoidance(this.mdr).subscribe((data) => {
        this.sumAvoid = 0;
        data.forEach((elem) => {
          this.sumAvoid += Number(elem.value) / 100;
        });
        this.costAvoidances = data;
        // console.log({'CA': this.costAvoidances});
      });
      // pega a lista de UOCs possíveis
      this.mdrService.getListUOC().subscribe((data) => {
        this.uoc = data;
      });
      // pega a lista de CoPQs possíveis
      this.mdrService.getListCoPQ().subscribe( (data) => {
        this.copq = data;
      });
      // quando os UOCs mudarem
      this.mdrService.getUOC(this.mdr).subscribe((data) => {
        this.sumUOC = 0;
        // console.log(data);
        data.forEach((elem) => {
          elem.value = Number(elem.value) / 100;
          this.sumUOC += elem.value;
          elem.value = Number(elem.value.toFixed(2));
        });
        this.sumUOC /= 100;
        this.UOCs = data;
        // pega os Payments
        this.mdrService.getStatusPayment(this.mdr.id).subscribe( (payments) => {
          let frete_count = 0;
          let frete_paid = 0;
          // itera sobre os pagamentos
          for (let i = 0 ; i < payments.length ; i++ ) {
            if (payments[i].origem === 'FRETE') {
              frete_count = frete_count + 1;
              if (payments[i].status === 'PAID') {
                frete_paid = frete_paid + 1;
              }
            } else { // pra origem='UOC'
              const uoc = this.UOCs.find((u) => (u.id === parseInt(payments[i].origemId, 10)));
              if (!uoc) {
                // pagamento nao está presente na lista de pagamentos
              } else if (payments[i].status === 'PAID') {
                uoc['color'] = 'green';
              } else if (payments[i].status === 'READY_TO_PAY') {
                uoc['color'] = 'red';
              } else {
                uoc['color'] = 'undefined';
              }
            }
          }
          if ( frete_count === frete_paid ) {
            this.payField.color = 'green';
          } else if (frete_paid === 0) {
            this.payField.color = 'red';
          } else {
            this.payField.color = 'yellow';
          }
        });
      });
      if (this.mdr.status === MDR_STATUS.MDR_EM_CRIACAO || this.mdr.status === MDR_STATUS.NOVA_MDR || this.mdr.status === MDR_STATUS.ENTREGA_DE_CARGA_CERTIFICADA_PELO_LSP) {
        this.showCalculations();
      }
    });
  }

  editValues() {
    this.editing_freight = true;
  }

  saveValues() {
    this.editing_freight = false;
    // save freight values
    this.savePrice();
    // save mdr.totalNF and mdr.totalKM
    this.saveMDR();
  }

  removeUOC(uoc) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdrService.removeOccurrence(uoc).subscribe((elem) => {
          this.mdrService.getUOC(this.mdr).subscribe((data) => {
            this.snackBar.open('Custo deletado corretamente', 'Ok', {
              duration: 4000,
            });
            this.sumUOC = 0;
            data.forEach((item) => {
              item.value = Number(item.value) / 100;
              this.sumUOC += item.value;
              item.value = item.value.toFixed(2);
            });
            this.sumUOC /= 100;
            this.UOCs = data;
          });
        });
      }
    });
  }

  removeCostAvoidance(ca: CostAvoidanceMDR) {
    // console.log(ca);
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdrService.removeCostAvoidance(ca).subscribe(resp => {
          this.mdrService.getCostAvoidance(this.mdr).subscribe((data) => {
            this.snackBar.open('Custo deletado corretamente', 'Ok', {
              duration: 4000,
            });
            this.sumAvoid = 0;
            data.forEach((elem) => {
              this.sumAvoid += Number(elem.value) / 100;
            });
            this.costAvoidances = data;
          });
        });
      }
    });
  }

  removeCOPQ(copq: COPQMDR) {
   // console.log(copq);
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '600px',
      data: {
        text: '',
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response[0] === true) {
        this.mdrService.removeOccurrenceCOPQ(copq).subscribe((elem) => {
          this.mdrService.getCoPQ(this.mdr).subscribe((data) => {
            this.snackBar.open('Custo deletado corretamente', 'Ok', {
              duration: 4000,
            });
            this.sumCOPQ = 0;
            data.forEach((item) => {
              item.value = Number(item.value) / 100;
              this.sumCOPQ += item.value;
              item.value = item.value.toFixed(2);
            });
            this.sumCOPQ /= 100;
            this.COPQs = data;
          });
        });
      }
    });
  }

  sanitizeInputMdr(field: string, value: any, tag: any) {
    let input = value.srcElement.value;
    this.update = false;
    input = input.replace('R$', '');
    input = input.replace(/ /g, '');
    input = input.replace(/\./g, '');
    input = input.replace(',', '.');
    input = input.replace(/[^\d.]/g, '');
    this.mdr[field] = input;
    this.update = true;
  }

  sanitizeInputPrice(field: string, value: any, tag: any) {
    let input = value.srcElement.value;
    this.update = false;
    input = input.replace('R$', '');
    input = input.replace(' ', '');
    input = input.replace('.', '');
    input = input.replace(',', '.');
    input = input.replace(/[^\d.]/g, '');
    this.price[field] = input;
    this.update = true;
  }

  amountCOPQ() {
    this.sumCOPQ = 0;
    this.COPQs.forEach((elem) => {
      this.sumCOPQ += Number(elem.value);
    });
    return (this.sumCOPQ);
  }

  amountCA() {
    this.sumAvoid = 0;
    this.costAvoidances.forEach((elem) => {
      this.sumAvoid += Number(elem.value);
    });
    return this.sumAvoid;
  }

  saveConveyor() {
    this.saveMDR();
  }

  saveMDR() {
    this.mdrService.update(this.mdr, this.user).subscribe((response) => {
      // console.log(response);
    });
  }

  savePrice() {
   // console.log(this.price);
    this.mdrService.savePrice(this.price, this.mdr).subscribe( (data) => {
     // console.log('teste');
    });
  }

  getTotal() {
    return this.price.total;
  }

  saveUOC() {

  }

  showCalculations() {
    this.region = TaxComponent.UFtoRegion(this.mdr);
    this.calculationsShouldBeShown = !this.calculationsShouldBeShown;
  }

  saveCOPQ() {
    this.COPQs.forEach((copq) => {
      this.mdrService.createCoPQ(copq).subscribe((response) => {
        // console.log(response);
        this.mdrService.getCoPQ(this.mdr).subscribe((data) => {
          this.sumCOPQ = 0;
          data.forEach((elem) => {
            this.sumCOPQ += Number(elem.value);
          });
          this.COPQs = data;
        });
      });
    });
  }

  updateTotalKM(newTotalDistance) {
    this.mdr.totalKM = newTotalDistance;
    this.getAllPriceWithKm(true);
  }

  getAllPriceWithKm(saveAfter = false) {
    // can't calculate prices without fiscal in/out states
    if (this.mdr.fiscalInState === null || this.mdr.fiscalOutState === null) {
      this.snackBar.open('É necessário preencher os estados fiscais de coleta e de entrega.', 'Ok', {
        duration: 4000,
      });
      return;
    }
    this.mdrService.getAllPriceWithKm(this.mdr).subscribe( (data) => {
      if (data) {
        this.mdr.totalKM = data.km;
        this.price = data;
        if (saveAfter) {
          this.saveValues();
        }
      }
    });
  }

  saveCA() {
    this.costAvoidances.forEach((costAv) => {
      this.mdrService.createCostAvoidance(costAv).subscribe((response) => {
        // console.log(response);
        this.mdrService.getCostAvoidance(this.mdr).subscribe((data) => {
          this.sumAvoid = 0;
          data.forEach((elem) => {
            this.sumAvoid += Number(elem.value);
          });
          this.costAvoidances = data;
        });
      });
    });
  }
  amountUOC() {
    this.sumUOC = 0;
    this.UOCs.forEach( (elem) => {
      this.sumUOC += Number(elem.value) *  Number(elem['multiplicator']);
    });
    return this.sumUOC;
  }
  amountTotal() {
    this.sumTotal = Number(this.mdr.valorem) + Number(this.mdr.valorem) + Number(this.mdr.additional);
    return this.sumTotal;
  }
  addCOPQ() {
    this.COPQs.push(new COPQMDR);
    this.COPQs[this.COPQs.length - 1].idMDR = this.mdr.id;
  }
  addCA() {
    const dialogRef = this.dialog.open(CreateCostAvoidanceComponent, {
      width: '600px',
      data: {
        mdr: this.mdr,
      }
    });
    dialogRef.afterClosed().subscribe( (response) => {
      if (response) {
        if (response.data) {
          this.mdrService.getCostAvoidance(this.mdr).subscribe((data) => {
            this.sumAvoid = 0;
            data.forEach((elem) => {
              this.sumAvoid += Number(elem.value) / 100;
            });
            this.costAvoidances = data;
          });
        }
      }
    });

  }
  toggleClass() {
    if (this.statusContainer === true) {
      this.statusContainer = false;
    } else {
      this.statusContainer = true;
    }
  }

  download() {
    const json = [];
    json.push({
      0: 'MDR ID',
      1: 'Veiculo',
      2: 'Data de coleta',
      3: 'Total NF subida',
      4: 'KM',
      5: 'Total',
      6: 'Tipo',
      7: 'Obs'
    });

    json.push({
      0: this.mdr.pickupID,
      1: this.mdr.vehicle,
      2: this.dpipe.transform(this.mdr.pickupDate, 'dd/MM/yyyy hh:mm'),
      3: this.cpipe.transform(this.nfePrice, 'BRL'),
      4: this.mdr.totalKM,
      5: this.cpipe.transform(this.price.total, 'BRL'),
      6: 'Frete',
      7: ''
    });

    for (const u of this.UOCs) {
      json.push({
        0: this.mdr.pickupID,
        1: this.mdr.vehicle,
        2: this.dpipe.transform(this.mdr.pickupDate, 'dd/MM/yyyy hh:mm'),
        3: this.cpipe.transform(this.nfePrice, 'BRL'),
        4: this.mdr.totalKM,
        5: this.cpipe.transform(u.value, 'BRL'),
        6: 'UOC',
        7: u.uoc
      });
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      skipHeader: true
    });
    const workbook: XLSX.WorkBook = { Sheets: { 'taxes': worksheet }, SheetNames: ['taxes'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.excel.saveAsExcelFile(excelBuffer, 'taxes');
  }

  openMdrFinance() {
    const columns = [
      { value: 'origem', name: 'origem'},
      { value: 'status', name: 'Status'},
      { value: 'client', name: 'Cliente'},
      { value: 'shippingCompany', name: 'Transp'},
      { value: 'purchaseOrder', name: 'PO'},
      { value: 'migo', name: 'MIGO'},
      { value: 'mdrPickupId', name: 'Romaneio'},
      { value: 'prolog', name: 'prolog'},
      { value: 'OV', name: 'Ordem de Venda'},
      { value: 'value', name: 'Valor'},
      { value: 'createdBy', name: 'Criado por'}
    ];
    const what = 'finance';
    this.mdrService.getMdrFinance(this.mdr.id).subscribe((history) => {
      const dialogRef = this.dialog.open(FinanceHistoryDialogComponent, {
        width: '80vw',
        maxHeight: '80vh',
        data: { history: history, columns: columns , what: what }
      });
    });
  }

  openHistoryModal() {
    const columns = [
      { value: 'frete', name: 'Valor Frete' },
      { value: 'freteTax', name: 'Frete com taxas' },
      { value: 'adValorem', name: 'adValorem' },
      { value: 'total', name: 'Frete total' },
      { value: 'updatedAt', name: 'Ultima atualização em' },
      { value: 'updatedBy', name: 'Ultima atualização por' }
    ];
    const what = 'taxes';
    this.subscriptions.push(this.mdrService.getAllPriceChanges(this.mdr.id).subscribe((history) => {
      const dialogRef = this.dialog.open(FinanceHistoryDialogComponent, {
        width: '80vw',
        maxHeight: '80vh',
        data: { history: history, columns: columns, what: what }
      });
    }));
  }

  editState(in_or_out) {
    // toggle select
    this.icmsState[in_or_out].editing = true;
  }

  saveState(in_or_out) {
    this.mdrService.update(this.mdr, this.user).subscribe((data) => {
      // toggle select
      this.icmsState[in_or_out].editing = false;
      // update values
      this.getAllPriceWithKm();
      // tell the user
      this.snackBar.open('Estado fiscal atualizado com sucesso.', 'Ok', {
        duration: 4000,
      });
    }, (err) => {
      this.snackBar.open('Erro ao atualizar estado fiscal.', 'Ok', {
        duration: 4000,
      });
    });
  }

}

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: 'confirm-delete-dialog.html',
  styleUrls: ['./../logistic-order.component.scss']
})
export class ConfirmDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService) {
    // console.log(translate);
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-create-cost-avoidance',
  templateUrl: 'create-cost-avoidance.html',
  styleUrls: ['./../logistic-order.component.scss']
})
export class CreateCostAvoidanceComponent {
  costAvoidanceList;
  caNew = new CostAvoidanceMDR();
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, public mdrService: MdrService) {
      this.caNew.idMDR = data.mdr.id;
  }

  ngOnInit() {
    this.mdrService.getListCostAvoidance().subscribe(elem => {
      this.costAvoidanceList = elem;
      // console.log(elem);
    });
  }

  onYesClick() {
    this.caNew.value = Math.floor(this.caNew.value * 100);
    this.mdrService.createCostAvoidance(this.caNew).subscribe(elem => {
      this.dialogRef.close({data: true});
    });
  }

  onNoClick() {
    this.dialogRef.close({data: false});
  }
}

@Component({
  selector: 'app-finance-history',
  templateUrl: './finance-history.html',
  styleUrls: ['./tax.component.scss']
})
export class FinanceHistoryDialogComponent implements OnInit {
  history = [];
  columns = [];
  dialog = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public datepipe: DatePipe, public mdrService: MdrService) {
    this.history = data.history;
    this.history.forEach(info => {
      info.updatedAt = this.datepipe.transform(info.updatedAt, 'dd/MM/yyyy HH:mm:ss');
    });
    this.columns = data.columns;
    this.dialog = data.what;
  }

  ngOnInit() {
    if (this.dialog === 'finance') {
      this.history.forEach(info => {
        info.value = info.value / 100;
      });
    } else if (this.dialog === 'taxes') {
      this.history.map(h => Number(h.freteTax).toFixed(2) && Number(h.adValorem).toFixed(2) && Number(h.total).toFixed(2));
    }

  }
}
