<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-12">
          {{ 'ATTACHMENTS/title' | translate }}
        </div>
      </div>
      <hr [style.margin-bottom]="'0'">

      <!-- upload manual de arquivos -->
      <div fxLayout="row" class="padding" fxLayoutAlign="space-between center">
        <div class="title center-text" fxFlex="100">
          {{ 'ATTACHMENTS/manual-upload' |translate }}
          <app-upload-files class="right" [entity]="'mdr'" [id]="mdr.id" (messageEvent)="receiveFile($event)" > </app-upload-files>
        </div>
      </div>
      <div *ngFor="let file of manuallyUploadedFiles"  class="padding"  fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <a [href]="file.path" target="_blank"> {{ file.fileOriginalName }} </a>
        </div>
        <button mat-raised-button class="right warn-button" (click)="removeFile(file, file.type)">
          {{ 'ATTACHMENTS/remove' | translate }}
        </button>
      </div>

      <!-- fim upload manual de arquivos -->

      <mat-divider></mat-divider>

      <!-- notas fiscais -->
      <div fxLayout="row" class="padding" fxLayoutAlign="space-between center">
        <div fxLayout="column">
          <div class="title">
            {{ 'ATTACHMENTS/nf' |translate }}
            <button *ngIf="user.role === 1" mat-icon-button matTooltip="Editar Notas associadas a MDR" [matMenuTriggerFor]="menu">
              <i font class="fa fa-pencil-alt icon-header click-button"></i>
            </button>
              <mat-menu #menu="matMenu">
                <button (click)="removeNfs(false)" mat-menu-item > Desassociar Nota </button>
                <button (click)="addNfs()" mat-menu-item> Associar Notas Fiscais </button>
                <button (click)="removeNfs(true)" mat-menu-item> Enviar Nota para Hub </button>
              </mat-menu>
              <button mat-icon-button matTooltip="Clique para fazer download das Notas associadas a MDR" (click)="dowloadAll()">
                <i class="fas fa-file-download"></i>
              </button>
          </div>
          <div class="subtitle"
            [ngClass]="{red: uniqueCount.nfs !== uniqueCount.relation}"
            [matTooltip]=" (missingFiles.length > 0) ? ('NFs/OVs ausentes: '+missingFiles.join(', ')) : undefined"
            matTooltipPosition='right'
          >
            {{uniqueCount.nfs}}/{{uniqueCount.relation}} {{'ATTACHMENTS/nf_count'|translate}}
          </div>
        </div>
      </div>
      <div class="padding" *ngFor="let file of invoiceFiles">
        <div *ngIf="file !== null">
          <a [href]="file.path" target="_blank"> {{ file.fileOriginalName }} </a>
        </div>
      </div>

      <mat-divider></mat-divider>

      <!-- PODs -->
      <div class="padding" fxLayout="column">
        <div class="title" fxFlex="100">
          {{ 'ATTACHMENTS/pod' | translate }}
        </div>
        <div class="subtitle">
          {{uniqueCount.pods}} {{'ATTACHMENTS/nf_pod_count'|translate}}
        </div>
      </div>
      <button mat-raised-button class="primary-button" (click)="openPODInfoModal(PODInfosMany)">
        {{ 'Edição de PODs' }}
      </button>
      <!-- PODs de ZVSD1 -->
      <div class="padding" *ngFor="let pod of podList.ovs" fxLayout="column" fxLayoutGap="15px">
        <div class="center-text">
          {{pod.salesDocument}} - {{pod.referenceNoNf}}
          <app-upload-files [id]="pod.referenceNoNf" [entity]="'nf'" (messageEvent)="receiveFile($event)"></app-upload-files>
        </div><!-- identificador -->

        <div *ngFor="let file of pod.files" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign=" center">

            <!-- bloco da esquerda -->
            <div fxFlex="25" fxLayout="column" [style.margin-right]="'15px'">
              <!-- margin-right settado para uma imagem alta não impedir de clicar no botão de copiar infos-->

              <!-- thumbnail do arquivo -->
              <app-image-lightbox [images]="[file]"></app-image-lightbox>

              <!-- quando arquivo foi subido -->
              <div fxFlex="100" [style.text-align]="'center'">
                {{ 'ATTACHMENTS/date' | translate }} {{ file.fileEntity.createdAt | date :'dd/MM/yyyy HH:mm:ss'}}
              </div>
            </div>

            <!-- bloco da direita -->
            <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxFlex="75">
              <!-- informações do responsavel do arquivo -->
              <form #form="ngForm" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                <mat-form-field fxFlex="100">
                  <input matInput required
                    [disabled]="true"
                    placeholder="{{ 'ATTACHMENTS/name' | translate }}"
                    name="info_name"
                    [(ngModel)]="file.fileInfo.name"
                  >
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    [disabled]="true"
                    placeholder="{{ 'ATTACHMENTS/rg' | translate }}"
                    name="info_rg"
                    [(ngModel)]="file.fileInfo.rg"
                  >
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    [disabled]="true"
                    placeholder="{{ 'ATTACHMENTS/delivery-date' | translate }}"
                    name="deliveredat"
                    [(ngModel)]="file.fileInfo.deliveredAt"
                    [owlDateTime]="datePicker"
                    [owlDateTimeTrigger]="datePicker"
                  >
                  <owl-date-time #datePicker [disabled]="true" pickerType="calendar"></owl-date-time>
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    [disabled]="true"  
                    placeholder="{{ 'ATTACHMENTS/hour' | translate }}"
                    name="timedeliveredat"
                    [(ngModel)]="file.fileInfo.timeDeliveredAt"
                    [owlDateTime]="timePicker"
                    [owlDateTimeTrigger]="timePicker"
                  >
                  <owl-date-time #timePicker [disabled]="true" pickerType="timer"></owl-date-time>
                </mat-form-field>
              </form><!-- fim informações do responsavel do arquivo -->

            </div>
          </div>
        </div>

      </div><!-- fim ngFor de podList.ovs -->

      <!-- PODs de NFe -->
      <div class="padding" *ngFor="let pod of podList.nfs" fxLayout="column" fxLayoutGap="15px">
        <div class="center-text">
          THIRDPARTY - {{pod.invoiceNumber}}
          <app-upload-files [id]="pod.invoiceNumber" [entity]="'nf'" (messageEvent)="receiveFile($event)"></app-upload-files>
        </div><!-- identificador -->

        <div *ngFor="let file of pod.files" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign=" center">

            <!-- bloco da esquerda -->
            <div fxFlex="25" fxLayout="column" [style.margin-right]="'15px'">
              <!-- margin-right settado para uma imagem alta não impedir de clicar no botão de copiar infos-->
              <!-- thumbnail do arquivo -->
              <app-image-lightbox [images]="[file]"></app-image-lightbox>

              <!-- quando arquivo foi subido -->
              <div fxFlex="100" [style.text-align]="'center'">
                {{ 'ATTACHMENTS/date' | translate }} {{ file.fileEntity.createdAt | date :'dd/MM/yyyy hh:mm:ss'}}
              </div>
            </div>

            <!-- bloco da direita -->
            <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxFlex="75">
              <!-- informações do responsavel do arquivo -->
              <form #form="ngForm" fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                <mat-form-field fxFlex="100">
                  <input matInput required
                    placeholder="{{ 'ATTACHMENTS/name' | translate }}"
                    name="info_name"
                    [disabled]="true"
                    [(ngModel)]="file.fileInfo.name"
                  >
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    placeholder="{{ 'ATTACHMENTS/rg' | translate }}"
                    name="info_rg"
                    [(ngModel)]="file.fileInfo.rg"
                    [disabled]="true"
                  >
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    placeholder="{{ 'ATTACHMENTS/delivery-date' | translate }}"
                    name="deliveredat"
                    [(ngModel)]="file.fileInfo.deliveredAt"
                    [owlDateTime]="datePicker"
                    [owlDateTimeTrigger]="datePicker"
                    [disabled]="true"
                  >
                  <owl-date-time #datePicker [disabled]="true" pickerType="calendar"></owl-date-time>
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    placeholder="{{ 'ATTACHMENTS/hour' | translate }}"
                    name="timedeliveredat"
                    [(ngModel)]="file.fileInfo.timeDeliveredAt"
                    [owlDateTime]="timePicker"
                    [owlDateTimeTrigger]="timePicker"
                    [disabled]="true"
                  >
                  <owl-date-time #timePicker [disabled]="true" pickerType="timer"></owl-date-time>
                </mat-form-field>
              </form><!-- fim informações do responsavel do arquivo -->
            </div>
          </div>
        </div>

      </div><!-- fim ngFor de podList.nfs -->

      <!-- PODs de CTE -->
      <div class="padding" *ngIf="podList.cte !== null" fxLayout="column" fxLayoutGap="15px">
        <div class="center-text">
          HUB - {{mdr.id}}
          <app-upload-files [id]="'CTE'+mdr.id" [entity]="'nf'" (messageEvent)="receiveFile($event)"></app-upload-files>
        </div><!-- identificador -->

        <div *ngFor="let file of podList.cte" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign=" center">

            <!-- bloco da esquerda -->
            <div fxFlex="25" fxLayout="column" [style.margin-right]="'15px'">
              <!-- margin-right settado para uma imagem alta não impedir de clicar no botão de copiar infos-->
              <!-- thumbnail do arquivo -->
              <app-image-lightbox [images]="[file]"></app-image-lightbox>

              <!-- quando arquivo foi subido -->
              <div fxFlex="100">
                {{ 'ATTACHMENTS/date' | translate }} {{ file.fileEntity.createdAt | date :'dd/MM/yyyy HH:mm:ss'}}
              </div>
            </div>

            <!-- bloco da direita -->
            <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxFlex="75">

              <!-- informações do responsavel do arquivo -->
              <form #form="ngForm" fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                <mat-form-field fxFlex="100">
                  <input matInput required 
                    [disabled]="true"
                    placeholder="{{ 'ATTACHMENTS/name' | translate }}"
                    name="info_name"
                    [(ngModel)]="file.fileInfo.name"
                  >
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    [disabled]="true"
                    placeholder="{{ 'ATTACHMENTS/rg' | translate }}"
                    name="info_rg"
                    [(ngModel)]="file.fileInfo.rg"
                  >
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    [disabled]="true"
                    placeholder="{{ 'ATTACHMENTS/delivery-date' | translate }}"
                    name="deliveredat"
                    [(ngModel)]="file.fileInfo.deliveredAt"
                    [owlDateTime]="datePicker"
                    [owlDateTimeTrigger]="datePicker"
                  >
                  <owl-date-time #datePicker pickerType="calendar"></owl-date-time>
                </mat-form-field>
                <mat-form-field fxFlex="33">
                  <input matInput required
                    [disabled]="true"
                    placeholder="{{ 'ATTACHMENTS/hour' | translate }}"
                    name="timedeliveredat"
                    [(ngModel)]="file.fileInfo.timeDeliveredAt"
                    [owlDateTime]="timePicker"
                    [owlDateTimeTrigger]="timePicker"
                  >
                  <owl-date-time #timePicker pickerType="timer"></owl-date-time>
                </mat-form-field>
              </form><!-- fim informações do responsavel do arquivo -->
            </div>
          </div>
        </div>
      </div><!-- fim ngIf de podList.cte -->
      <!-- fim POD -->

      <mat-divider></mat-divider>

      <!-- UOC -->
      <div class="padding" fxLayout="row">
        <div class="title" fxFlex="100">
          {{ 'ATTACHMENTS/uoc' | translate }}
        </div>
      </div>

      <!-- UOC -->
      <div class="padding" *ngFor="let uoc of uocList" class="row">
        <div class="column col-md-9" >
          {{ uoc.uoc }}
        </div>
        <div class="col-md-3 center-text"  >
          <app-upload-files [id]="uoc.id" [entity]="'uoc'" (messageEvent)="receiveFile($event)"  ></app-upload-files>
        </div>
        <div *ngFor="let file of uoc.files" class="col-md-12 row">
          <app-image-lightbox class="col-md-3"  [images]="[file]"></app-image-lightbox>
          <div fxFlex="100" >
            {{ 'ATTACHMENTS/date' | translate }} {{ file.createdAt | date :'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <button class="button red-button text-white remove"  mat-raised-button (click)="removeFile(file, 'uoc')"> {{ 'ATTACHMENTS/remove' | translate }} </button>
        </div>
      </div>
      <!-- fim UOC -->

    </div>
  </div>
</div>

<ng-template #PODInfosMany>
  <div>
    <h1 mat-dialog-title>
      {{'Edição de PODs'}}
    </h1>

    <app-table
      [data]="podData"
      [columns]="podColumns"
      [this]="this"
      (check)="setPODsEditing($event)"
      showCheckbox = "true"
    ></app-table>

    <ng-container *ngIf="podsToUpdate && podsToUpdate.length">
      <div [ngStyle]="{'margin-bottom': '15px'}">

        <ng-container>
          <div fxLayout="row" fxLayoutAlign="space-between stretch">
            <button *ngIf="allPODsCanBeEdited === true" [ngStyle]="{'margin-bottom': '10px'}" mat-flat-button class="blue-button"
              (click)="openPODTemplateModal()"
              [disabled]="podsToUpdate.length <= 1"
            >
              {{ 'Preencher PODs massivamente' }}
            </button>
      
            <button *ngIf="podsCanBeRemoved === true" [ngStyle]="{'margin-bottom': '10px'}" mat-flat-button class="blue-button"
              (click)="deleteFiles()"
            >
              {{ 'Deletar PODs massivamente' }}
            </button>
          </div>
        </ng-container>

        <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" *ngFor="let pod of podsToUpdate; index as i">
          <div fxFlex="100" fxLayoutGap="25px">
            <span> {{ pod.reference }}</span>
            <i class="fa fa-copy" matTooltip="{{ 'ATTACHMENTS/copy' | translate }}" (click)="copyData(pod)"></i>
            <i class="fa fa-paste" matTooltip="{{ 'ATTACHMENTS/paste' | translate }}" (click)="pasteData(pod)"></i>
          </div>

          <mat-form-field fxFlex="100">
            <input matInput required
              placeholder="{{ 'ATTACHMENTS/name' | translate }}"
              name="name"
              [(ngModel)]="podsToUpdate[i].name"
              [disabled]="podsToUpdate[i].disabled"
            >
          </mat-form-field>
          <mat-form-field fxFlex="33">
            <input matInput required
              placeholder="{{ 'ATTACHMENTS/rg' | translate }}"
              name="rg"
              [(ngModel)]="podsToUpdate[i].rg"
              [disabled]="podsToUpdate[i].disabled"
            >
          </mat-form-field>
          <mat-form-field fxFlex="33">
            <input matInput required
              placeholder="{{ 'ATTACHMENTS/delivery-date' | translate }}"
              name="deliveredat"
              [(ngModel)]="podsToUpdate[i].deliveredAt"
              [owlDateTime]="datePicker"
              [owlDateTimeTrigger]="datePicker"
              [disabled]="podsToUpdate[i].disabled"
            >
            <owl-date-time #datePicker pickerType="calendar"></owl-date-time>
          </mat-form-field>
          <mat-form-field fxFlex="33">
            <input matInput required
              placeholder="{{ 'ATTACHMENTS/hour' | translate }}"
              name="timedeliveredat"
              [(ngModel)]="podsToUpdate[i].timeDeliveredAt"
              [owlDateTime]="timePicker"
              [owlDateTimeTrigger]="timePicker"
              [disabled]="podsToUpdate[i].disabled"
            >
            <owl-date-time #timePicker pickerType="timer"></owl-date-time>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-between w-100">
    <button mat-button [mat-dialog-close]="true"  >{{ 'Confirm' | translate }}</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial >{{ 'Cancel' | translate }}</button>
  </div>
</ng-template>

<ng-template #PODTemplate>
  <div fxLayout="row wrap" fxLayoutAlign="space-between stretch" mat-dialog-content>
    <mat-form-field fxFlex="100">
      <input matInput required
        placeholder="{{ 'ATTACHMENTS/name' | translate }}"
        name="name"
        [(ngModel)]="templatePODData.name"
      >
    </mat-form-field>
    <mat-form-field fxFlex="33">
      <input matInput required
        placeholder="{{ 'ATTACHMENTS/rg' | translate }}"
        name="rg"
        [(ngModel)]="templatePODData.rg"
      >
    </mat-form-field>
    <mat-form-field fxFlex="33">
      <input matInput required
        placeholder="{{ 'ATTACHMENTS/delivery-date' | translate }}"
        name="deliveredat"
        [(ngModel)]="templatePODData.deliveredAt"
        [owlDateTime]="datePicker"
        [owlDateTimeTrigger]="datePicker"
      >
      <owl-date-time #datePicker pickerType="calendar"></owl-date-time>
    </mat-form-field>
    <mat-form-field fxFlex="33">
      <input matInput required
        placeholder="{{ 'ATTACHMENTS/hour' | translate }}"
        name="timedeliveredat"
        [(ngModel)]="templatePODData.timeDeliveredAt"
        [owlDateTime]="timePicker"
        [owlDateTimeTrigger]="timePicker"
      >
      <owl-date-time #timePicker pickerType="timer"></owl-date-time>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="d-flex justify-content-between w-100">
    <button mat-button [mat-dialog-close]="true"  >{{ 'Confirm' | translate }}</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial >{{ 'Cancel' | translate }}</button>
  </div>
</ng-template>

<ng-template #ConfirmRemoval>
  <h1 mat-dialog-title>
    {{'Remover PODs'}}
  </h1>
  <div [ngStyle]="{'margin-bottom': '10px'}" mat-dialog-content >
    Tem certeza que deseja deletar os PODs selecionados? Para continuar a remoção, clique no botão de confirmação.
  </div>
  <div mat-dialog-actions class="d-flex justify-content-between w-100">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial >{{ 'Confirm' | translate }}</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial >{{ 'Cancel' | translate }}</button>
  </div>
</ng-template>