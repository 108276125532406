var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ElementRef } from '@angular/core';
import { MatTableDataSource, MatSort, MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DrService } from '../../../providers/dr.service';
import { DrObservable } from '../../../services/dr.service';
import { TranslateService } from '@ngx-translate/core';
import { HubService } from '../../../providers/hub.service';
import { HUBDR } from '../../../models/hub__dr';
import { OVService } from '../../../providers/ov.service';
import { MDR, MDR_STATUS, MDR_TYPE } from '../../../models/mdr';
import { ESTRUTURA } from '../../../providers/site.service';
import { ClientUserService } from '../../../providers/client-user.service';
import { DatePipe } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { MdrService } from '../../../providers/mdr.service';
var DeliveryRouteComponent = /** @class */ (function () {
    function DeliveryRouteComponent(drService, aRoute, dialog, drObservable, hubService, ovService, snackBar, mdrService) {
        this.drService = drService;
        this.aRoute = aRoute;
        this.dialog = dialog;
        this.drObservable = drObservable;
        this.hubService = hubService;
        this.ovService = ovService;
        this.snackBar = snackBar;
        this.mdrService = mdrService;
        this.data = [];
        this.dataSource = new MatTableDataSource(this.data);
        this.displayedColumns = ['idDRidentifier', 'deliveryAddress', 'receiverName', 'receiverPhone', 'supervisorName', 'supervisorPhone', 'site', 'dateScheduling', 'status', 'pickupDate', 'arrivalTime', 'client', 'spec-ops', 'obs', 'view'];
        this.columnsToDisplay = ['deliveryAddress', 'site', 'status', 'dateScheduling', 'pickupDate', 'arrivalTime', 'client'];
        this.siteNomes = {
            'form_6_1': 'Grua',
            'form_6_2': 'Correia',
            'form_6_3': 'Veículos Esp.',
            'form_6_4': 'Operação Manual',
            'form_6_5': 'Guindates'
        };
        this.drColumns = [
            { value: 'idDRidentifier', name: 'id' },
            { value: 'deliveryAddress', name: 'DR_OPTIMIZER/address' },
            { value: 'dateScheduling', name: 'DR_OPTIMIZER/date' },
            { value: 'site', name: 'DR_OPTIMIZER/site' },
            { value: 'createdBy', name: 'DR_OPTIMIZER/created' },
            { value: 'client', name: 'DR_OPTIMIZER/table/client' },
            { value: 'ovList', name: 'DR_OPTIMIZER/table/ovList' },
            { value: 'uf', name: 'DR_OPTIMIZER/table/uf' }
        ];
        this.drsToAdd = [];
        this.drsLoaded = false;
        this.selectedHub = [];
        this.canAddDR = false;
        this.canMergeDR = false;
        this.mdrsLoaded = false;
        this.canEditDr = false;
        this.selectedMDRs = [];
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        if (this.role !== 5) { // pra role!==5 adiciona botoes de editar e visualizar
            this.displayedColumns = [].concat(this.displayedColumns, ['action', 'view']);
        }
    }
    DeliveryRouteComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.mdr.status === MDR_STATUS.MDR_EM_CRIACAO && this.role === 1) {
            this.canEditDr = true;
            if (this.mdr.type === MDR_TYPE.CROSS_DOCKING) {
                if (this.mdr.SAD !== null && this.mdr.SAD !== '') {
                    this.canAddDR = true;
                }
                else {
                    this.canMergeDR = true;
                }
            }
            else {
                this.canAddDR = true;
            }
        }
        this.drService.getByMdr(this.mdr.id).subscribe(function (drs) {
            for (var _i = 0, drs_1 = drs; _i < drs_1.length; _i++) {
                var dr = drs_1[_i];
                if (dr.ovList) {
                    dr.ovList = Array.from(new Set(dr.ovList));
                }
                dr.site_form6 = [];
                if (dr.site_form) {
                    for (var _a = 0, _b = Object.entries(dr.site_form); _a < _b.length; _a++) {
                        var _c = _b[_a], key = _c[0], value = _c[1];
                        if (!key.startsWith('form_6_') || key.includes('obs_')) {
                            continue;
                        }
                        if (value === ESTRUTURA.EX) {
                            dr.site_form6.push(_this.siteNomes[key]);
                        }
                    }
                }
                var zs = dr.zvsd1s.map(function (z) { return z.salesDocument; });
                var ns = dr.nfes.map(function (n) { return n.invoiceNumber; });
                dr.salesOrders = Array.from(new Set(zs.concat(ns).filter(function (v) { return !!v; })));
            }
            _this.data = drs;
            _this.dataSource = new MatTableDataSource(_this.data);
        });
    };
    DeliveryRouteComponent.prototype.setUserArrivalTime = function (dr) {
        var _this = this;
        dr.userArrivalTime = new Date();
        this.drService.update(dr).subscribe(function (response) {
            if (response === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
            }
            else {
                dr.userArrivalTime = null;
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    DeliveryRouteComponent.prototype.setCoronaNull = function (dr) {
        var _this = this;
        dr.unificationId = null;
        this.drService.update(dr).subscribe(function (response) {
            if (response === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
            }
            else {
                dr.userArrivalTime = null;
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    DeliveryRouteComponent.prototype.openEditDialog = function (dr) {
        var _this = this;
        var dialogRef = this.dialog.open(EditDrDialogComponent, {
            data: {
                role: this.role,
                dr: dr,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            _this.ngOnInit();
        });
    };
    DeliveryRouteComponent.prototype.openExpedition = function () {
        // console.log('OPEN');
        var dialogRef = this.dialog.open(ViewExpeditionComponent, {
            height: '800px',
            data: {
                mdr: this.mdr,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
        });
    };
    DeliveryRouteComponent.prototype.listOVNF = function () {
        // console.log('OPEN');
        var dialogRef = this.dialog.open(ViewNfOvComponent, {
            height: '600px',
            data: {
                mdr: this.mdr,
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
        });
    };
    DeliveryRouteComponent.prototype.openViewDialog = function (dr) {
        var dialogRef = this.dialog.open(ViewInfoComponent, {
            width: '600px',
            data: {
                dr: dr,
                role: this.role
            },
        });
        dialogRef.afterClosed().subscribe(function (response) {
            // console.log(response);
        });
    };
    DeliveryRouteComponent.prototype.openHistoryDialog = function (dr) {
        var _this = this;
        this.drService.getHistory(dr.id).subscribe(function (history) {
            var dialogRef = _this.dialog.open(HistoryDialogComponent, {
                width: '80vw',
                maxHeight: '80vh',
                data: { history: history }
            });
        });
    };
    DeliveryRouteComponent.prototype.getDialogData = function () {
        var _this = this;
        this.drService.getAllOpen().subscribe(function (drList) {
            if (drList && drList.length) {
                for (var _i = 0, drList_1 = drList; _i < drList_1.length; _i++) {
                    var dr = drList_1[_i];
                    if (!dr.ovList) {
                        dr.ovList = '';
                    }
                }
                _this.drsFree = drList;
                _this.drsLoaded = true;
            }
        });
        if (this.mdr.type === MDR_TYPE.CROSS_DOCKING) {
            this.hubService.getAll(true).subscribe(function (hubsResponse) {
                if (hubsResponse && hubsResponse.length) {
                    _this.hubList = hubsResponse;
                }
            });
        }
    };
    DeliveryRouteComponent.prototype.openDRAssociationDialog = function () {
        var _this = this;
        this.getDialogData();
        var dialogRef = this.dialog.open(this.drAssociationModal, {
            width: '80vw',
            maxHeight: '80vh',
            data: {
                drList: this.drsFree
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response && response === true) {
                _this.addDRsToMDR();
            }
            _this.resetFields();
        });
    };
    DeliveryRouteComponent.prototype.addToDRList = function (selectedDR) {
        var uniqueDRs = Array.from(new Set(selectedDR.slice()));
        this.drsToAdd = uniqueDRs.map(function (dr) { return (__assign({}, dr, { selectedHub: dr.selectedHub || null })); });
    };
    DeliveryRouteComponent.prototype.addDRsToMDR = function () {
        var _this = this;
        var body = {
            mdrToEdit: this.mdr.id,
            drsToAdd: this.drsToAdd.map(function (d) { return ({
                drId: d.id,
                hubSelected: d.selectedHub ? d.selectedHub : null
            }); })
        };
        this.mdrService.addToMDR(body).subscribe(function (resp) {
            _this.snackBar.open('DRs associadas com sucesso', 'Ok', { duration: 4000 });
        }, function (error) {
            _this.snackBar.open('Erro ao associar DRs', 'Ok', { duration: 4000 });
        });
    };
    DeliveryRouteComponent.prototype.resetFields = function () {
        this.drsToAdd = [];
        this.drsLoaded = false;
    };
    DeliveryRouteComponent.prototype.openMergeMDRModal = function () {
        var _this = this;
        this.mdrService.getChildRelations(this.mdr.id).subscribe(function (relatedMDRs) {
            if (relatedMDRs && relatedMDRs.length) {
                _this.avaibleMdrs = relatedMDRs.filter(function (mdr) { return mdr.id !== _this.mdr.id; });
                _this.mdrsLoaded = true;
            }
        });
        var dialogRef = this.dialog.open(this.mergeMdrModal, {
            width: '80vw',
            maxHeight: '80vh',
            data: {
                mdrList: this.avaibleMdrs
            }
        });
        dialogRef.afterClosed().subscribe(function (response) {
            if (response && response === true && _this.selectedMDRs && _this.selectedMDRs.length > 0) {
                _this.mergeMDRs();
                _this.selectedMDRs = [];
            }
        });
    };
    DeliveryRouteComponent.prototype.mergeMDRs = function () {
        var _this = this;
        var body = {
            toUpdate: this.mdr.id,
            toCancel: this.selectedMDRs
        };
        this.mdrService.mergeMDRs(body).subscribe(function (resp) {
            if (resp) {
                _this.snackBar.open('MDRs mergeadas com sucesso', 'Ok', { duration: 5000 });
            }
            else {
                _this.snackBar.open('Erro ao mergear MDRs', 'Ok', { duration: 5000 });
            }
        });
    };
    return DeliveryRouteComponent;
}());
export { DeliveryRouteComponent };
var EditDrDialogComponent = /** @class */ (function () {
    function EditDrDialogComponent(data, clientService, dialogRef, drService, hubService, mapsAPILoader, snackBar, translate) {
        var _this = this;
        this.data = data;
        this.clientService = clientService;
        this.dialogRef = dialogRef;
        this.drService = drService;
        this.hubService = hubService;
        this.mapsAPILoader = mapsAPILoader;
        this.snackBar = snackBar;
        this.translate = translate;
        this.hubList = [];
        this.currentUser = null;
        this.role = null;
        this.allowed = false;
        this.allowedCampo = false;
        this.clientList = [];
        this.valid = true;
        this.aspList = [{ name: 'Servicetel', value: 1 }];
        this.statusList = ['InTransit',
            'READY_TO_PICKUP',
            'Devolução/Trânsito',
            'Devolvido EDB',
            'Replan - Retido',
            'Replan - Sefaz',
            'Replan - Pernoite',
            'Replan - Antecipação',
            'Replan - Postergação',
            'HubReturn',
            'Canceled',
            'Delivered',
            'Sinistro'];
        this.hubService.getAll(this.valid).subscribe(function (hubs) {
            _this.hubList = hubs;
        });
        this.clientService.getClients().subscribe(function (clients) {
            _this.clientList = clients.map(function (e) { return e.clientGroup; });
        });
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.role = this.currentUser.user.role;
        if (this.role === 1) { // pra role!==5 adiciona botoes de editar e visualizar
            this.allowed = true;
            // console.log(this.allowed);
        }
        if (this.role === 1 || this.role === 12 || this.role === 5) { // roles que podem editar o site (Campo)
            this.allowedCampo = true;
            // console.log(this.allowed);
        }
    }
    EditDrDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        // faz uma copia da DR para edição
        this.current = Object.assign({}, this.data.dr);
        this.mapsAPILoader.load().then(function () {
            var autocomplete = new google.maps.places.Autocomplete(_this.searchElement2Ref.nativeElement, {
                types: ['address']
            });
            autocomplete.addListener('place_changed', function () {
                // console.log('Place Changed');
                var place = autocomplete.getPlace();
                _this.current.deliveryAddress = place.formatted_address;
                _this.current.lat = place.geometry.location.lat();
                _this.current.lng = place.geometry.location.lng();
            });
        });
    };
    EditDrDialogComponent.prototype.recalculateLegs = function () {
        var _this = this;
        if (this.data.dr.lat !== this.current.lat || this.data.dr.lng !== this.current.lng) {
            this.drService.recalculateLeg(this.current.lat, this.current.lng, this.data.dr).subscribe(function (resp) {
                _this.snackBar.open('Alterado a posição de entrega encontrada', 'Ok', { duration: 4000 });
                _this.data.dr = Object.assign({}, resp.dr);
            });
        }
        else {
            this.snackBar.open('Não houve alteração de pontos para mudança de posição', 'Ok', { duration: 4000 });
        }
    };
    EditDrDialogComponent.prototype.onYesClick = function () {
        var _this = this;
        // compara DR copiada para edição com DR anterior
        this.current.deliveryAddress = document.getElementById('address').value;
        var checkChanges = function (key) {
            return (_this.current[key] !== _this.data.dr[key]);
        };
        // se nao houve alguma modificação
        var keys = Object.keys(this.current);
        if (!keys.some(checkChanges)) {
            this.snackBar.open('Nenhum valor foi alterado', 'Ok', { duration: 2000 });
            this.dialogRef.close();
            return;
        }
        if (this.current.status === 'HubReturn') {
            var newHUBDR = new HUBDR();
            newHUBDR.hubID = this.hubId;
            newHUBDR.drID = this.data.dr.id;
            newHUBDR.when = new Date();
            newHUBDR.observation = this.hubObs;
            this.hubService.createHubDr(newHUBDR).subscribe(function (elem) {
                // console.log(elem);
            });
        }
        var today = new Date();
        today.setDate(today.getDate() - 30);
        if (this.current.dateScheduling < today) {
            this.snackBar.open('Data de Entrega inválida.', 'Ok', { duration: 3000 });
        }
        this.drService.update(this.current).subscribe(function (response) {
            if (response === true) {
                _this.snackBar.open('DR atualizada com sucesso', 'Ok', { duration: 2000 });
                // copia valores pra DR anterior
                keys.forEach(function (key) {
                    _this.data.dr[key] = _this.current[key];
                });
                _this.dialogRef.close();
            }
            else {
                _this.snackBar.open('Erro ao atualizar DR, tente novamente', 'Ok', { duration: 2000 });
            }
        });
    };
    EditDrDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    return EditDrDialogComponent;
}());
export { EditDrDialogComponent };
var ViewInfoComponent = /** @class */ (function () {
    function ViewInfoComponent(data, drService) {
        this.data = data;
        this.drService = drService;
        this.dataSource = [];
        this.HUs = [];
        this.columns = [
            { value: 'salesDocument', name: 'OV' },
            { value: 'salesOrderQuantity', name: 'Quantity' },
            { value: 'materialCode', name: 'Material' },
            { value: 'customerName', name: 'Client' }
        ];
        this.role = data.role;
    }
    ViewInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.drService.getAllInfo(this.data.dr.id).subscribe(function (elem) {
            if (elem) {
                var dataFiltered_1 = [];
                var HUs_1 = [];
                (elem.drList).forEach(function (dr) {
                    // Removes unnecessary properties
                    var drFiltered = {};
                    _this.columns.forEach(function (column) {
                        // All the atributes are mapped with column.name
                        // to allow cleaner xlsx column names in the download file
                        drFiltered[column.name] = dr[column.value];
                    });
                    HUs_1 = HUs_1.concat(dr.HUs);
                    dataFiltered_1.push(drFiltered);
                });
                if (elem.relationHUs && elem.relationHUs.length > 0) {
                    HUs_1 = elem.relationHUs;
                }
                // Updates column values
                _this.columns.forEach(function (column) {
                    column.value = column.name;
                });
                // remove HUs repetidas
                var unique = function (value, index, self) {
                    return self.indexOf(value) === index;
                };
                _this.HUs = HUs_1.filter(unique);
                _this.dataSource = dataFiltered_1;
            }
        });
    };
    return ViewInfoComponent;
}());
export { ViewInfoComponent };
var ViewNfOvComponent = /** @class */ (function () {
    function ViewNfOvComponent(data, datepipe, ovService) {
        var _this = this;
        this.data = data;
        this.datepipe = datepipe;
        this.ovService = ovService;
        this.history = [];
        this.columns = [
            { value: 'ov', name: 'OV' },
            { value: 'nf', name: 'NF' }
        ];
        this.ovService.getNfByMdr(this.data.mdr.id).subscribe(function (el) {
            // console.log(el);
            _this.history = el;
        });
    }
    ViewNfOvComponent.prototype.ngOnInit = function () { };
    return ViewNfOvComponent;
}());
export { ViewNfOvComponent };
var HistoryDialogComponent = /** @class */ (function () {
    function HistoryDialogComponent(data, datepipe) {
        var _this = this;
        this.data = data;
        this.datepipe = datepipe;
        this.history = [];
        this.currentUser = null;
        this.role = null;
        this.allowed = false;
        this.columns = [
            { value: 'deliveryAddress', name: 'DELIVERY_ROUTE/address' },
            { value: 'status', name: 'status' },
            { value: 'quem', name: 'DELIVERY_ROUTE/by' },
            { value: 'quando', name: 'DELIVERY_ROUTE/on' },
            { value: 'dateScheduling', name: 'DELIVERY_ROUTE/date' }
        ];
        this.history = data.history;
        this.history.forEach(function (day) {
            day.dateScheduling = new Date(day.dateScheduling);
            day.dateScheduling = _this.datepipe.transform(day.dateScheduling, 'dd/MM/yyyy hh:mm:ss');
            day.quando = new Date(day.quando);
            day.quando = _this.datepipe.transform(day.quando, 'dd/MM/yyyy hh:mm:ss');
        });
    }
    HistoryDialogComponent.prototype.ngOnInit = function () {
    };
    return HistoryDialogComponent;
}());
export { HistoryDialogComponent };
var ViewExpeditionComponent = /** @class */ (function () {
    function ViewExpeditionComponent(data, datepipe) {
        this.data = data;
        this.datepipe = datepipe;
        this.mdr = null;
        this.mdr = data.mdr;
    }
    ViewExpeditionComponent.prototype.ngOnInit = function () {
    };
    return ViewExpeditionComponent;
}());
export { ViewExpeditionComponent };
