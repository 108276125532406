var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as dateFormat from 'dateformat';
import { XlsxService } from '../../services/xlsx.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
var TableComponent = /** @class */ (function () {
    function TableComponent(xlsx, dialog, router, cookie) {
        this.xlsx = xlsx;
        this.dialog = dialog;
        this.router = router;
        this.cookie = cookie;
        this.columns = [];
        this.data = [];
        // callbacks
        this.styleCallback = null;
        this.clickCallback = null;
        // relativos a checkboxes
        this.showCheckbox = false;
        this.check = new EventEmitter();
        this.dateSearchChanges = new EventEmitter();
        this.selectColumnName = 'select';
        this.selection = new SelectionModel(true, []);
        // relativos a busca com chips
        this.removable = true;
        this.selectable = true;
        this.addOnBlur = true;
        this.separatorKeyCodes = [ENTER, COMMA];
        this.chips = [];
        // relativos a busca de data
        this.dateSearch = [];
        this.startDate = null;
        this.endDate = null;
        this.dates = [null, null];
        this.downloadShowId = false;
        // relativos a formatação
        this.decimalPoints = 2;
        // relativos a busca avançada
        this.showAdvancedSearch = false;
        this.advancedSearchValues = [];
        this.disableCookie = false;
        this.uidSufix = '';
    }
    TableComponent.prototype.ngOnInit = function () {
        this.dates = [this.startDate ? this.startDate : null, this.endDate ? this.endDate : null];
        this.searchType.value = 'and';
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setUniqueID();
        this.loadSearches();
        this.loadColumns();
    };
    TableComponent.prototype.ngOnChanges = function (changes) {
        // inicializa a tabela
        this.setColumns();
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.initSelection();
    };
    TableComponent.prototype.getAllDataFromChangesInDateFilter = function (emitChanges) {
        if (emitChanges) {
            this.dateSearchChanges.emit(this.dates);
        }
    };
    TableComponent.prototype.ngOnDestroy = function () {
        this.clearSelected();
    };
    /********************
     * auxiliares a salvar buscas
     */
    TableComponent.prototype.setUniqueID = function () {
        // TODO componente pai para usar como id unico (https://angular.io/guide/dependency-injection-navtree)
        var sufix = this.uidSufix || '';
        this.uid = [this.router.url].join('-') + (sufix.length > 0 ? "-" + sufix : '');
    };
    TableComponent.prototype.saveSearches = function () {
        if (this.disableCookie) {
            return;
        }
        this.cookie.set(this.uid + 'chips', JSON.stringify(this.chips));
        this.cookie.set(this.uid + 'searchType', JSON.stringify(this.searchType.value));
        this.cookie.set(this.uid + 'dates', JSON.stringify(this.dates));
        this.cookie.set(this.uid + 'advancedSearchValues', JSON.stringify(this.advancedSearchValues));
        this.cookie.set(this.uid + 'showAdvancedSearch', JSON.stringify(this.showAdvancedSearch));
    };
    TableComponent.prototype.loadSearches = function () {
        if (this.disableCookie) {
            return;
        }
        if (this.cookie.check(this.uid + 'chips')) {
            try {
                this.chips = JSON.parse(this.cookie.get(this.uid + 'chips'));
            }
            catch (ex) { }
        }
        if (this.cookie.check(this.uid + 'searchType')) {
            try {
                this.searchType.value = JSON.parse(this.cookie.get(this.uid + 'searchType'));
            }
            catch (ex) { }
        }
        if (this.cookie.check(this.uid + 'dates')) {
            try {
                var dates = JSON.parse(this.cookie.get(this.uid + 'dates'));
                if (!this.dates[0] && dates[0] !== null) {
                    this.dates[0] = new Date(dates[0]);
                }
                if (!this.dates[1] && dates[1] !== null) {
                    this.dates[1] = new Date(dates[1]);
                }
            }
            catch (ex) { }
        }
        if (this.cookie.check(this.uid + 'advancedSearchValues')) {
            try {
                this.advancedSearchValues = JSON.parse(this.cookie.get(this.uid + 'advancedSearchValues'));
            }
            catch (ex) { }
        }
        if (this.cookie.check(this.uid + 'showAdvancedSearch')) {
            try {
                this.showAdvancedSearch = JSON.parse(this.cookie.get(this.uid + 'showAdvancedSearch'));
            }
            catch (ex) { }
        }
        this.applyFilter();
    };
    /********************
     * auxiliares a formatação das células
     */
    TableComponent.prototype.typeof = function (row, name, type) {
        if ('icon' in this.columns.find(function (e) { return e.value === name; })) {
            return 'icon';
        }
        else if (Object.prototype.toString.call(row[name]) === '[object Date]') {
            return 'date';
        }
        else if ('currency' in this.columns.find(function (e) { return e.value === name; })) {
            return 'currency';
        }
        else if (typeof row[name] === 'number' && row[name] % 1 !== 0) {
            return 'float';
        }
        else if (row[type] && row[type] === 'image') {
            return 'image';
        }
        else if (type === 'time') {
            return 'time';
        }
        else if (type === 'onlyDate') {
            return 'onlyDate';
        }
        else {
            return typeof row[name];
        }
    };
    TableComponent.prototype.rowCurrency = function (name) {
        return this.columns.find(function (e) { return e.value === name; }).currency;
    };
    /********************
     * estilo da célula
     */
    TableComponent.prototype.style = function (row) {
        if (this.styleCallback) {
            return this.styleCallback(row, this.parent);
        }
    };
    /*********************
     * ao clicar na linha
     */
    TableComponent.prototype.onClick = function (row) {
        if (this.clickCallback) {
            this.clickCallback(row, this.parent);
        }
    };
    /***********************
     * relativos a checkboxes
     */
    TableComponent.prototype.initSelection = function () {
        var _this = this;
        this.selection.clear();
        this.dataSource.data.forEach(function (row) {
            if (row.selected) {
                if (row.notSelectable) {
                    _this.selection.deselect(row);
                }
                else {
                    _this.selection.select(row);
                }
            }
        });
        this.applyFilter();
    };
    TableComponent.prototype.clearSelected = function () {
        this.selection.clear();
        this.dataSource.filteredData.forEach(function (row) {
            row.selected = false;
        });
    };
    TableComponent.prototype.isAllSelected = function () {
        var _this = this;
        var selectableData = this.dataSource.filteredData.filter(function (row) { return !row.notSelectable; });
        return (selectableData.length === selectableData.filter(function (row) { return _this.selection.isSelected(row); }).length);
    };
    TableComponent.prototype.checkboxLabel = function (row) {
        if (!row) {
            return (this.isAllSelected() ? 'select' : 'deselect') + " all";
        }
        return (this.selection.isSelected(row) ? 'deselect' : 'select') + " row " + (row.position + 1);
    };
    TableComponent.prototype.masterToggle = function () {
        var _this = this;
        if (this.isAllSelected()) {
            this.dataSource.filteredData.forEach(function (row) {
                _this.selection.deselect(row);
                row.selected = false;
            });
        }
        else {
            this.dataSource.filteredData.forEach(function (row) {
                if (!row.notSelectable) {
                    _this.selection.select(row);
                    row.selected = true;
                }
            });
        }
    };
    TableComponent.prototype.onSelection = function (event, row) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (event) {
                    if (row) {
                        this.selection.toggle(row);
                        row.selected = this.selection.isSelected(row);
                    }
                    else {
                        this.masterToggle();
                    }
                }
                this.check.emit(this.selection.selected);
                return [2 /*return*/];
            });
        });
    };
    /***********************
     * relativos a busca simples
     */
    TableComponent.prototype.addChip = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            for (var _i = 0, _a = value.split(','); _i < _a.length; _i++) {
                var text = _a[_i];
                this.chips.push({ 'name': text.trim() });
            }
        }
        if (input) {
            input.value = '';
        }
        this.applyFilter();
    };
    TableComponent.prototype.removeChip = function (chip) {
        var index = this.chips.indexOf(chip);
        if (index >= 0) {
            this.chips.splice(index, 1);
        }
        this.applyFilter();
    };
    TableComponent.prototype.getChips = function () {
        return this.chips.map(function (e) { return e.name; });
    };
    TableComponent.prototype.applySimpleSearch = function () {
        var _this = this;
        // busca
        this.dataSource.filterPredicate = function (data, mergedFilter) {
            // função de busca por chip
            var checkChip = function (chip) {
                return _this.columns.map(function (e) { return e.value; }).some(function (key) {
                    if (key in data && data[key] !== null) {
                        switch (typeof (data[key])) {
                            case 'string':
                                return (data[key].toLowerCase().indexOf(chip.toLowerCase()) !== -1);
                                break;
                            case 'number':
                                return (parseFloat(data[key]) === parseFloat(chip));
                                break;
                        }
                    }
                    return false;
                });
            };
            // função de busca por data
            var checkDate = function (date) {
                // nao é Date
                if (Object.prototype.toString.call(data[date]) !== '[object Date]') {
                    return false;
                }
                if (_this.uid = '/finance-list') {
                    return true;
                }
                // data inicial
                if (_this.dates[0] && data[date].getTime() < _this.dates[0].getTime()) {
                    return false;
                }
                // data final
                if (_this.dates[1] && data[date].getTime() > _this.dates[1].getTime()) {
                    return false;
                }
                return true;
            };
            /***************
             *  Tabela verdade da busca:
             *
             *  | E/OU | data   | chips  | busca realizada             |
             *  |------+--------+--------+-----------------------------|
             *  | E    | EXISTE | vazio  | some(data)                  |
             *  | E    | vazio  | EXISTE | every(chips)                |
             *  | E    | EXISTE | EXISTE | some(data) E every(chips)   |
             *  | OU   | EXISTE | vazio  | some(data)                  |
             *  | OU   | vazio  | EXISTE | some(chips)                 |
             *  | OU   | EXISTE | EXISTE | some(data) OU some(chips)   |
             *  | E/OU | vazio  | vazio  | true                        |
             *
             */
            // realiza a busca
            var chips = _this.getChips();
            // busca sem chips
            if (chips.length === 0) {
                if (_this.dates[0] === null && _this.dates[1] === null) { // nenhuma busca
                    return true;
                }
                else { // busca somente com data
                    return _this.dateSearch.some(checkDate);
                }
            }
            // busca sem data
            if (_this.dates[0] === null && _this.dates[1] === null) {
                if (chips.length === 0) { // nenhuma busca (redundante)
                    return true;
                }
                else { // busca somente com chips
                    if (_this.searchType.value === 'and') { // busca com E lógico
                        return chips.every(checkChip);
                    }
                    else { // busca com OU lógico
                        return chips.some(checkChip);
                    }
                }
            }
            // busca com chips e data
            if (_this.searchType.value === 'and') { // busca com E lógico
                return chips.every(checkChip) && _this.dateSearch.some(checkDate);
            }
            else { // busca com OU lógico
                return chips.some(checkChip) || _this.dateSearch.some(checkDate);
            }
        };
        // atribuição necessária pra aplicar a busca
        this.dataSource.filter = 'this.dataSource.data';
        // paginação
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    };
    /************************
     * relativos a busca avançada
     */
    TableComponent.prototype.addSearchField = function () {
        this.advancedSearchValues.push({
            field: '',
            chips: [],
        });
    };
    TableComponent.prototype.removeSearchField = function (i) {
        this.advancedSearchValues.splice(i, 1);
        this.applyFilter();
    };
    TableComponent.prototype.getFields = function () {
        return this.advancedSearchValues.filter(function (e) {
            return (e.chips.length > 0 && e.field !== '');
        });
    };
    TableComponent.prototype.addChipFromAdvanced = function (searchChips, event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            for (var _i = 0, _a = value.split(','); _i < _a.length; _i++) {
                var text = _a[_i];
                searchChips.push({ 'name': text.trim() });
            }
        }
        if (input) {
            input.value = '';
        }
        this.applyFilter();
    };
    TableComponent.prototype.removeChipFromAdvanced = function (searchChips, chip) {
        var index = searchChips.indexOf(chip);
        if (index >= 0) {
            searchChips.splice(index, 1);
        }
        this.applyFilter();
    };
    TableComponent.prototype.applyAdvancedSearch = function () {
        var _this = this;
        // busca
        this.dataSource.filterPredicate = function (data, mergedFilter) {
            // função de busca a partir de um campo
            var checkField = function (search) {
                if (search.field in data && data[search.field] !== null) {
                    switch (typeof (data[search.field])) {
                        case 'string':
                            // busca com OU lógico dentre os chips
                            return search.chips.some(function (chip) {
                                return (data[search.field].toLowerCase().indexOf(chip.name.toLowerCase()) !== -1);
                            });
                            break;
                        case 'number':
                            // busca com OU lógico dentre os chips
                            return search.chips.some(function (chip) {
                                return (parseFloat(data[search.field]) === parseFloat(chip.name));
                            });
                            break;
                    }
                }
                return false;
            };
            // função de busca por data
            var checkDate = function (date) {
                // nao é Date
                if (Object.prototype.toString.call(data[date]) !== '[object Date]') {
                    return false;
                }
                // data inicial
                if (_this.dates[0] && data[date].getTime() < _this.dates[0].getTime()) {
                    return false;
                }
                // data final
                if (_this.dates[1] && data[date].getTime() > _this.dates[1].getTime()) {
                    return false;
                }
                return true;
            };
            /***************
             *  Tabela verdade da busca:
             *
             *  | E/OU | data   | fields  | busca realizada              |
             *  |------+--------+---------+------------------------------|
             *  | E    | EXISTE | vazio   | some(data)                   |
             *  | E    | vazio  | EXISTE  | every(fields)                |
             *  | E    | EXISTE | EXISTE  | some(data) E every(fields)   |
             *  | OU   | EXISTE | vazio   | some(data)                   |
             *  | OU   | vazio  | EXISTE  | some(fields)                 |
             *  | OU   | EXISTE | EXISTE  | some(data) OU some(fields)   |
             *  | E/OU | vazio  | vazio   | true                         |
             *
             */
            // realiza a busca
            var fields = _this.getFields();
            // busca sem fields
            if (fields.length === 0) {
                if (_this.dates[0] === null && _this.dates[1] === null) { // nenhuma busca
                    return true;
                }
                else { // busca somente com data
                    return _this.dateSearch.some(checkDate);
                }
            }
            // busca sem data
            if (_this.dates[0] === null && _this.dates[1] === null) {
                if (fields.length === 0) { // nenhuma busca (redundante)
                    return true;
                }
                else { // busca somente com fields
                    if (_this.searchType.value === 'and') { // busca com E lógico
                        return fields.every(checkField);
                    }
                    else { // busca com OU lógico
                        return fields.some(checkField);
                    }
                }
            }
            // busca com fields e data
            if (_this.searchType.value === 'and') { // busca com E lógico
                // console.log(fields.every(checkField), this.dateSearch.some(checkDate));
                return fields.every(checkField) && _this.dateSearch.some(checkDate);
            }
            else { // busca com OU lógico
                return fields.some(checkField) || _this.dateSearch.some(checkDate);
            }
        };
        // atribuição necessária pra aplicar a busca
        this.dataSource.filter = 'this.dataSource.data';
        // paginação
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    };
    /************************
     * relativos à troca entre busca simples e avançada
     */
    TableComponent.prototype.applyFilter = function (dateChanges) {
        if (dateChanges === void 0) { dateChanges = false; }
        this.getAllDataFromChangesInDateFilter(dateChanges);
        this.saveSearches();
        if (this.showAdvancedSearch) {
            this.applyAdvancedSearch();
        }
        else {
            this.applySimpleSearch();
        }
    };
    TableComponent.prototype.toggleAdvancedSearch = function () {
        this.showAdvancedSearch = !this.showAdvancedSearch;
        this.applyFilter();
    };
    /************************
     * relativos a download
     */
    TableComponent.prototype.flatten = function (data) {
        // tirado de : https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
        var result = {};
        function recurse(cur, prop) {
            if (Object(cur) !== cur) {
                result[prop] = cur;
            }
            else if (Array.isArray(cur)) {
                var i = void 0, l = void 0;
                for (i = 0, l = cur.length; i < l; i++) {
                    recurse(cur[i], prop + '[' + i + ']');
                }
                if (l === 0) {
                    result[prop] = [];
                }
            }
            else {
                var isEmpty = true;
                for (var _i = 0, _a = Object.keys(cur); _i < _a.length; _i++) {
                    var p = _a[_i];
                    isEmpty = false;
                    recurse(cur[p], prop ? prop + '.' + p : p);
                }
                if (isEmpty && prop) {
                    result[prop] = {};
                }
            }
        }
        recurse(data, '');
        return result;
    };
    TableComponent.prototype.flatmap = function (obj) {
        var flat = {};
        // pega os campos (menos id)
        var keys = null;
        if (this.downloadShowId) {
            keys = obj ? Object.keys(obj).filter(function (e) { return e; }) : [];
        }
        else {
            keys = obj ? Object.keys(obj).filter(function (e) { return e !== 'id'; }) : [];
        }
        // itera sobre todos os campos
        for (var k = 0, lk = keys.length; k < lk; k++) {
            if (Object.prototype.toString.call(obj[keys[k]]) === '[object Date]') {
                // Date
                // formata
                flat[keys[k]] = dateFormat(obj[keys[k]], 'mmm d, yyyy, HH:MM:ss');
            }
            else if (typeof obj[keys[k]] !== 'object') {
                // não é object
                // retorna o valor como está
                flat[keys[k]] = obj[keys[k]];
            }
            else {
                // nested object
                // chama recursivamente
                flat[keys[k]] = this.flatmap(obj[keys[k]]);
            }
        }
        return this.flatten(flat);
    };
    TableComponent.prototype.download = function () {
        var table = [];
        // itera sobre todos os valores que aparecem
        for (var i = 0, l = this.dataSource.filteredData.length; i < l; i++) {
            var row = this.flatmap(this.dataSource.filteredData[i]);
            var newrow = {};
            // pega os campos
            var keys = Object.keys(row);
            // itera sobre todos os campos
            for (var k = 0, lk = keys.length; k < lk; k++) {
                // newrow[keys[k]] = this.formatField(row,keys[k]);
                newrow[keys[k]] = row[keys[k]];
                // console.log('%s[%s] : %s',keys[k],typeof row[keys[k]],newrow[keys[k]]);
            }
            table.push(newrow);
        }
        // exporta
        this.xlsx.export(table, this.downloadAs);
    };
    /************************
     * relativos a mostrar/ocultar colunas
     */
    TableComponent.prototype.prepareColumns = function () {
        this.columns.forEach(function (column) {
            if (!('show' in column)) {
                column['show'] = true;
            }
        });
    };
    TableComponent.prototype.setColumns = function () {
        // monta a lista de displayColumns
        this.prepareColumns();
        if (this.showCheckbox) {
            this.displayColumns = [this.selectColumnName].concat(this.columns.filter(function (e) { return e.show; }).map(function (e) { return e.value; }));
        }
        else {
            this.displayColumns = this.columns.filter(function (e) { return e.show; }).map(function (e) { return e.value; });
        }
    };
    TableComponent.prototype.toggleColumns = function () {
        var _this = this;
        var dialogRef = this.dialog.open(TableToggleColumnsDialogComponent, {
            maxHeight: '80vh',
            data: {
                columns: this.columns
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.setColumns();
            _this.saveColumns();
        });
    };
    TableComponent.prototype.saveColumns = function () {
        if (this.disableCookie) {
            return;
        }
        this.cookie.set(this.uid + 'columns', JSON.stringify(this.columns));
    };
    TableComponent.prototype.loadColumns = function () {
        if (this.disableCookie) {
            return;
        }
        if (this.cookie.check(this.uid + 'columns')) {
            var savedColumns_1 = JSON.parse(this.cookie.get(this.uid + 'columns'));
            // pega valores .show de valores salvos
            this.columns.forEach(function (col) {
                var savedCol = savedColumns_1.find(function (c) { return c.value === col.value; });
                col.show = (savedCol.show === true);
            });
        }
        this.setColumns();
    };
    return TableComponent;
}());
export { TableComponent };
var TableToggleColumnsDialogComponent = /** @class */ (function () {
    function TableToggleColumnsDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    TableToggleColumnsDialogComponent.prototype.onYesClick = function () {
        this.dialogRef.close();
    };
    return TableToggleColumnsDialogComponent;
}());
export { TableToggleColumnsDialogComponent };
