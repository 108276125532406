import { OnInit, ElementRef, QueryList, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { DrService } from '../../providers/dr.service';
import { MdrService } from '../../providers/mdr.service';
import { SocketService } from '../../providers/socket.service';
import { Event } from '../../models/events';
import { DrCardComponent } from './dr-card/dr-card.component';
import { ExcelService } from '../../misc/export-xlsx/export-xlsx';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { MDR_STATUS } from '../../models/mdr';
var CockpitComponent = /** @class */ (function () {
    function CockpitComponent(drService, pDate, socketService, snackBar, excelService, dialog, cookie) {
        var _this = this;
        this.drService = drService;
        this.pDate = pDate;
        this.socketService = socketService;
        this.snackBar = snackBar;
        this.excelService = excelService;
        this.dialog = dialog;
        this.cookie = cookie;
        this.undefinedStatus = 'Outros Status';
        this.displayList = [
            'Returned by LSP',
            'New MDR',
            'Planning MDR',
            'Waiting LSP',
            'READY_TO_PICKUP',
            'InTransit',
            'Delivered',
            'HubReturn',
            'Retido / Replan',
            'Approved by LSP',
            'MDR bloqueada por Avaria'
        ];
        this.statusList = [
            'Waiting LSP',
            'Approved by LSP',
            'Planning MDR',
            'Nova MDR',
            'Returned by LSP',
            'READY_TO_PICKUP',
            'InTransit',
            'Delivered',
            'Replan - Antecipação',
            'Replan - Postergação',
            'Replan - Pernoite',
            'Replan - Retido',
            'Replan - Sefaz',
            'HubReturn',
            'Devolução/Trânsito',
            'MDR bloqueada por Avaria'
        ]; // todas as strings aqui deve estar presentes em statusNameMap !!
        this.statusNameMap = {
            'Waiting LSP': 'Waiting LSP',
            'Planning MDR': 'Planning MDR',
            'Nova MDR': 'New MDR',
            'Approved by LSP': 'Approved by LSP',
            'Returned by LSP': 'Returned by LSP',
            'Replan - Antecipação': 'Retido / Replan',
            'Replan - Postergação': 'Retido / Replan',
            'Replan - Retido': 'Retido / Replan',
            'Replan - Pernoite': 'InTransit',
            'Replan - Sefaz': 'Retido / Replan',
            'READY_TO_PICKUP': 'READY_TO_PICKUP',
            'HubReturn': 'HubReturn',
            'InTransit': 'InTransit',
            'Delivered': 'Delivered',
            'MDR bloqueada por Avaria': 'MDR bloqueada por Avaria',
            'Devolução/Trânsito': 'InTransit'
        }; // key = status do card ; value = coluna na qual o card será colocado
        this.drLists = {};
        this.colors = {};
        this.selectedColor = null;
        this.colorList = ['green', 'red', 'yellow', 'undefined'];
        this.colorStarter = {};
        this.drList = [];
        this.searchDates = [null, null];
        this.searchChips = [];
        this.removable = true;
        this.selectable = true;
        this.addOnBlur = true;
        this.separatorKeyCodes = [ENTER, COMMA];
        this.searchTerm = '';
        this.validSearchFields = ['dr.idMDR', 'pickupID', 'dr.dateScheduling', 'dr.client', 'dr.site', 'shippingCompany', 'dr.createdBy'];
        this.search_toggle = false;
        this.uid = 'cockpit-';
        this.subscriptions = [];
        // inicializa cores
        this.colorList.forEach(function (color) {
            _this.colorStarter[color] = 0;
        });
        var statusList = [].concat(this.displayList, this.undefinedStatus);
        statusList.forEach(function (el, i, arr) {
            // inicializa drLists
            _this.drLists[el] = [];
            // incializa colors
            _this.colors[el] = Object.assign({}, _this.colorStarter);
        });
        var role = JSON.parse(localStorage.getItem('currentUser')).user.role;
        this.role = role;
        if (role === 5 || role === 12 || role === 14) { // lsp
            this.chatTypes = [6]; // embarcadora <-> transportadora
        }
        else if (role === 1 || role === 2 || role === 3) { // ericsson
            this.chatTypes = [5, 6]; // motorista <-> transportadora ; embarcadora <-> transportadora
        }
    }
    CockpitComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initIoConnection();
        this.subscriptions.push(this.drService.getForCockpit().subscribe(function (data) {
            _this.organizeData(data);
        }));
    };
    CockpitComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    CockpitComponent.prototype.checkDrCards = function () {
        var _this = this;
        var sleepAmount = 500; // 0.5s
        if (this.drList.length > 0
            && this.drList.length === this.drCardList.length) {
            this.loadSearches();
        }
        else {
            setTimeout(function () { _this.checkDrCards(); }, sleepAmount);
        }
    };
    CockpitComponent.prototype.getStatusName = function (status) {
        if (this.statusList.indexOf(status) !== -1) {
            return this.statusNameMap[status];
        }
        else {
            return this.undefinedStatus;
        }
    };
    CockpitComponent.prototype.initIoConnection = function () {
        var _this = this;
        this.socketService.initSocket('cockpit');
        this.subscriptions.push(this.ioConnection = this.socketService.onEvent(Event.UPDATE_DR)
            .subscribe(function (data) {
            if (data.what === 'dr created') { // o backend não retorna valores se a DR não puder ser lida pelo usuário logado
                _this.subscriptions.push(_this.drService.getById(data.status.id).subscribe(function (dr) {
                    _this.drLists[_this.getStatusName(dr.status)].push(dr);
                }));
            }
            else if (data.what === 'dr updated') { // atualiza somente se o card antigo já estiver sendo mostrado
                var oldStatus_1 = '';
                // encontra a coluna do card antigo
                _this.displayList.forEach(function (el, i, arr) {
                    if (_this.drLists[el].map(function (e) { return e.id; }).indexOf(data.status.id) !== -1) {
                        oldStatus_1 = el;
                    }
                });
                var position = _this.drLists[_this.getStatusName(oldStatus_1)].map(function (e) { return e.id; }).indexOf(data.status.id);
                if (oldStatus_1 !== '') {
                    // remove o card antigo
                    _this.drLists[_this.getStatusName(oldStatus_1)].splice(position, 1);
                    // adiciona o card novo
                    _this.drLists[_this.getStatusName(data.status.status)].unshift(data.status);
                }
            }
            else if (data.what === 'new occurrence') { // atualiza somente se o card antigo já estiver sendo mostrado
                var oldStatus_2 = '';
                // encontra a coluna do card antigo
                _this.displayList.forEach(function (el, i, arr) {
                    if (_this.drLists[el].map(function (e) { return e.id; }).indexOf(data.status.id) !== -1) {
                        oldStatus_2 = el;
                    }
                });
                var position = _this.drLists[_this.getStatusName(data.status.status)].map(function (e) { return e.id; }).indexOf(data.status.id);
                if (oldStatus_2 !== '') {
                    // remove o card antigo
                    _this.drLists[_this.getStatusName(oldStatus_2)].splice(position, 1);
                    // adiciona o card novo
                    _this.drLists[_this.getStatusName(data.status.status)].unshift(data.status);
                }
            }
            else if (data.what === 'new message') { // não está sendo enviado pelo back
                if (_this.chatTypes.indexOf(Number(data.status.chatType)) !== -1 && data.status.userRole !== _this.role) {
                    // console.log('atualiza mensagens no card',data.status.mdrId);//DEBUG
                    var updated = _this.drCardList.find(function (el, i, arr) {
                        if (parseInt(el.dr.idMDR, 10) === parseInt(data.status.mdrId, 10)) {
                            return true;
                        }
                        return false;
                    });
                    updated.novasMensagens[data.status.chatType] = true;
                }
            }
        }));
        this.subscriptions.push(this.socketService.onEvent(Event.CONNECT)
            .subscribe(function () {
            // console.log('connected');
        }));
        this.subscriptions.push(this.socketService.onEvent(Event.DISCONNECT)
            .subscribe(function () {
            // console.log('disconnected');
        }));
    };
    CockpitComponent.prototype.organizeData = function (data) {
        var _this = this;
        // reseta contagem de cores
        this.resetColors();
        // pra cada DR
        data.forEach(function (el, i, arr) {
            // corrige campos que são Date
            ['dateScheduling'].forEach(function (key) {
                el[key] = new Date(el[key]);
            });
            // setta dr.status de acordo com status da MDR associada
            if (el.mdr !== undefined) {
                if (el.mdr.status === 'MDR aguardando LSP') {
                    el.status = 'Waiting LSP';
                }
                else if (el.mdr.status === 'Entrega de carga certificada pelo LSP') {
                    el.status = 'Approved by LSP';
                }
                else if (el.mdr.status === 'MDR devolvida por LSP') {
                    el.status = 'Returned by LSP';
                }
                else if (el.mdr.status === 'Nova MDR') {
                    el.status = 'Nova MDR';
                }
                else if (el.mdr.status === MDR_STATUS.MDR_COM_AVARIA) {
                    el.status = 'MDR bloqueada por Avaria';
                }
            }
            var status = _this.getStatusName(el.status);
            // setta cor
            _this.setStatusColor(el);
            // da push na coluna de acordo com mapa de status
            _this.drLists[status].push(el);
            // atualiza cores
            _this.colors[status][el.color]++;
        });
        // salva DRs para referencia
        this.drList = data;
        // aguarda DRs carregarem para carregar busca salva
        this.checkDrCards();
    };
    CockpitComponent.prototype.resetColors = function () {
        var _this = this;
        // inicializa contadores de cores
        var statusList = [].concat(this.displayList, this.undefinedStatus);
        statusList.forEach(function (el, i, arr) {
            _this.colors[el] = Object.assign({}, _this.colorStarter);
        });
    };
    CockpitComponent.prototype.setColor = function (color) {
        if (this.selectedColor === color) {
            this.selectedColor = null;
        }
        else {
            this.selectedColor = color;
        }
        return this.selectedColor;
    };
    CockpitComponent.prototype.getColor = function () {
        return this.selectedColor;
    };
    CockpitComponent.prototype.dateChanged = function () {
        this.applyFilter();
    };
    CockpitComponent.prototype.applyFilter = function () {
        var _this = this;
        this.saveSearches();
        // valores da busca
        var dates = {
            start: this.searchDates[0],
            end: this.searchDates[1]
        };
        var chips = this.searchChips;
        var chipsSearchFields = this.validSearchFields;
        // reseta cores
        this.resetColors();
        // pra cada <dr-card>
        this.drCardList.forEach(function (drcard, i, arr) {
            var dr = drcard.dr;
            // console.log(dr);
            // valida datas
            var checkDates = function (searchDates) { return true; };
            if (dates.start || dates.end) {
                checkDates = function (searchDates) {
                    var drTime = dr.dateScheduling.getTime();
                    if (searchDates.start && drTime < searchDates.start.getTime()) {
                        return false;
                    }
                    if (searchDates.end && searchDates.end.getTime() <= drTime) {
                        return false;
                    }
                    return true;
                };
            }
            var displayFromDate = checkDates(dates);
            // valida chips
            var checkChip = function (chip) { return true; };
            if (chipsSearchFields.length) {
                checkChip = function (chip) {
                    return chipsSearchFields.some(function (el) {
                        // se for um campo da dr
                        if (el.indexOf('dr.') === 0) {
                            el = el.split('.')[1];
                            if (el in dr && dr[el] !== null) {
                                if (typeof dr[el] === 'string' &&
                                    dr[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                                    return true;
                                }
                                else if (typeof dr[el] === 'number' &&
                                    dr[el] === parseInt(chip, 10)) {
                                    return true;
                                }
                            }
                            return false;
                        }
                        else { // se for um campo do card
                            if (drcard[el] !== null) {
                                if (typeof drcard[el] === 'string' &&
                                    drcard[el].toLowerCase().indexOf(chip.toLowerCase()) !== -1) {
                                    return true;
                                }
                                else if (typeof drcard[el] === 'number' &&
                                    drcard[el] === parseInt(chip, 10)) {
                                    return true;
                                }
                            }
                            return false;
                        }
                    });
                };
            }
            var displayFromChips = _this.search_toggle
                ? chips.map(function (el) { return el.name; }).some(checkChip)
                : chips.map(function (el) { return el.name; }).every(checkChip);
            // oculta/mostra <dr-card>
            drcard.show = displayFromChips && displayFromDate;
            // atualiza cores
            if (drcard.show) {
                _this.colors[_this.getStatusName(dr.status)][dr.color]++;
            }
        });
    };
    CockpitComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        if ((value || '').trim()) {
            for (var _i = 0, _a = value.split(','); _i < _a.length; _i++) {
                var text = _a[_i];
                this.searchChips.push({ 'name': text.trim() });
            }
        }
        if (input) {
            input.value = '';
        }
        this.applyFilter();
    };
    CockpitComponent.prototype.remove = function (chip) {
        var index = this.searchChips.indexOf(chip);
        if (index >= 0) {
            this.searchChips.splice(index, 1);
        }
        this.applyFilter();
    };
    CockpitComponent.prototype.trackByFn = function (index, item) {
        return index;
    };
    CockpitComponent.prototype.exportAsXlsx2 = function () {
        var _this = this;
        var mdrs = [];
        this.drCardList.forEach(function (drCard) {
            if (drCard.show === true) {
                mdrs.push(drCard.dr.idMDR);
            }
        });
        this.subscriptions.push(this.drService.getCostsByMdrList(mdrs).subscribe(function (el) {
            // console.log(el);
            el.forEach(function (row) {
                row['pickupDate'] = _this.pDate.transform(row['pickupDate'], 'dd/MM/yyyy HH:mm');
            });
            _this.excelService.exportAsExcelFile(el, 'relatorios');
        }));
    };
    CockpitComponent.prototype.exportAsXlsx = function () {
        var _this = this;
        var list = [];
        var drs = [];
        this.drCardList.forEach(function (drCard) {
            if (drCard.show === true) {
                var card = drCard;
                var dr = {};
                dr['client'] = card.dr.client;
                dr['dateScheduling'] = _this.pDate.transform(card.dr.dateScheduling, 'dd/MM/yyyy HH:mm');
                dr['deliveryAddress'] = card.dr.deliveryAddress;
                dr['deliveryAddressCity'] = card.dr.city ? card.dr.city : 'Sem cidade';
                dr['deliveryAddressUf'] = card.dr.uf ? card.dr.uf : 'Sem estado';
                dr['driverPositionCreated'] = card.dr.driverPosition ? card.dr.driverPosition.createdAt : '';
                dr['shippingCompany'] = card.dr.mdr ? card.dr.mdr.shippingCompany : '';
                dr['createdBy'] = card.dr.createdBy;
                dr['pickupID'] = card.dr.mdr.pickupID;
                dr['pickupAddress'] = card.dr.pickupAddress;
                dr['site'] = card.dr.site;
                dr['status'] = card.dr.status;
                dr['salesOrders'] = card.dr.nfsAndOvs.join(', ');
                dr['OccurrenceLastStatus'] = card.dr.occurrences.length > 0 ? card.dr.occurrences[0].status : '';
                dr['mdrStatus'] = card.dr.mdr ? card.dr.mdr.status : '';
                dr['SLA'] = card.dr.mdr ? card.dr.mdr.aeroDelivery : '';
                dr['vehicle'] = card.dr.mdr ? card.dr.mdr.vehicle : '';
                dr['pickupDate'] = card.dr.mdr ? _this.pDate.transform(card.dr.mdr.pickupDate, 'dd/MM/yyyy HH:mm') : '';
                drs.push(dr);
            }
        });
        this.excelService.exportAsExcelFile(drs, 'relatorios');
    };
    CockpitComponent.prototype.openMap = function () {
        var drList = this.drCardList.filter(function (e) {
            return e.show === true && e.dr.status === 'InTransit';
        });
        var dialogRef = this.dialog.open(MapDialogComponent, {
            width: '800px',
            data: drList,
        });
    };
    CockpitComponent.prototype.setStatusColor = function (dr) {
        var tooltip = [];
        var color = 'green';
        var truckSaw = true;
        var hora = 1000 * 60 * 60;
        var now = Date.now();
        var novasMensagens = {
            5: false,
            6: false
        };
        if (dr === null) {
            return;
        }
        if ('status' in dr && dr.status === 'Approved by LSP') {
            if ('mdr' in dr && 'updatedAt' in dr.mdr && dr.mdr.updatedAt === null) {
                // se a MDR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else if ('mdr' in dr && 'updatedAt' in dr.mdr) {
                var d = new Date(dr.mdr.updatedAt);
                if (now - d.getTime() < 12 * hora) {
                    // se a última atualização ocorreu a menos de 1h
                    color = 'green';
                }
                else if (now - d.getTime() < 24 * hora) {
                    // se a última atualização ocorreu a menos de 2h
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 12h');
                }
                else {
                    // se a última atualização ocorreu a mais de 2h
                    color = 'red';
                    tooltip.push('última atualização a mais de 24h');
                }
            }
        }
        // se existe um caminhao para verificar
        if ('status' in dr && dr.status === 'InTransit') {
            if (!('driverPosition' in dr) || !dr.driverPosition || !dr.driverPosition.createdAt) {
                // se o caminhao ainda nao foi visto
                // color = 'red';
                truckSaw = false;
                tooltip.push('caminhão não encontrado');
            }
            else {
                var d = new Date(dr.driverPosition.createdAt);
                if (now - d.getTime() > 1 * hora) {
                    // se o caminhao nao é visto a mais de 1h
                    // color = 'red';
                    truckSaw = false;
                    tooltip.push('caminhão não é visto a mais de 1 hora');
                }
                else if (now - d.getTime() > 0.5 * hora) {
                    // se o caminhao nao é visto a mais de 30min
                    // color = (color === 'red') ? color : 'yellow';
                    tooltip.push('caminhão não é visto a mais de 30 minutos');
                }
            }
        }
        // se está aguardando atualizações da LSP
        if ('status' in dr && dr.status === 'Waiting LSP') {
            if ('updatedAt' in dr && dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 1 * hora) {
                    // se a última atualização ocorreu a menos de 1h
                    color = 'green';
                }
                else if (now - d.getTime() < 2 * hora) {
                    // se a última atualização ocorreu a menos de 2h
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 1h');
                }
                else {
                    // se a última atualização ocorreu a mais de 2h
                    color = 'red';
                    tooltip.push('última atualização a mais de 2h');
                }
            }
        }
        // se está pronto para coleta
        if ('status' in dr && dr.status === 'READY_TO_PICKUP') {
            if ('mdr.pickupDate' in dr && dr.mdr.pickupDate === null) {
                color = 'undefined';
                tooltip.push('data de coleta ausente');
            }
            else {
                var d = new Date(dr['mdr']['pickupDate']);
                if (d.getTime() - now > 1 * hora) {
                    // mais de 1h até a hora de coleta
                    color = 'green';
                }
                else if (d.getTime() - now > 0.5 * hora) {
                    // mais de 30min até a hora de coleta
                    color = 'yellow';
                    tooltip.push('menos de 1h até a hora de coleta');
                }
                else {
                    // menos de 30min até a hora de coleta
                    color = 'red';
                    tooltip.push('menos de 30 minutos até a hora de coleta');
                }
            }
        }
        // se já foi entregue
        if ('status' in dr && dr.status === 'Delivered') {
            if ('updatedAt' in dr && dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 6 * hora) {
                    // se a última atualização ocorreu a menos de 6h
                    color = 'green';
                }
                else if (now - d.getTime() < 12 * hora) {
                    // se a última atualização ocorreu a menos de 12h
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 6h');
                }
                else {
                    // se a última atualização ocorreu a mais de 12h
                    color = 'red';
                    tooltip.push('última atualização a mais de 12h');
                }
            }
        }
        if ('status' in dr &&
            ['HubReturn',
                'Replan - Retido',
                'Replan - Sefaz',
                'Replan - Antecipação',
                'Replan - Postergação',
                'Replan - Pernoite'].indexOf(dr.status) !== -1) {
            if ('updated' in dr && dr.updatedAt === null) {
                // se a DR ainda não foi atualizada
                color = 'undefined';
                tooltip.push('não há atualização');
            }
            else {
                var d = new Date(dr.updatedAt);
                if (now - d.getTime() < 5 * 24 * hora) {
                    // se a última atualização ocorreu a menos de 5d
                    color = 'green';
                }
                else if (now - d.getTime() < 10 * 24 * hora) {
                    // se a última atualização ocorreu a menos de 10d
                    color = 'yellow';
                    tooltip.push('última atualização a mais de 5 dias');
                }
                else {
                    // se a última atualização ocorreu a mais de 10d
                    color = 'red';
                    tooltip.push('última atualização a mais de 10 dias');
                }
            }
        }
        // verifica se ha mensagens nao lidas
        if ('idMDR' in dr && dr.idMDR !== null) {
            if (this.role === 5) { // lsp
                novasMensagens[6] = dr.messages.filter(function (msg) { return msg.chatType === 6; }).some(function (msg) { return !msg.seen; });
            }
            else if (this.role === 1 || this.role === 2 || this.role === 3) { // ericsson
                if ('messages' in dr && dr.messages !== undefined) {
                    novasMensagens[5] = dr.messages.filter(function (msg) { return msg.chatType === 5; }).some(function (msg) { return !msg.seen; });
                    novasMensagens[6] = dr.messages.filter(function (msg) { return msg.chatType === 6; }).some(function (msg) { return !msg.seen; });
                }
            }
        }
        // verifica as ocorrencias da MDR
        if ('occurrences' in dr && dr.occurrences.length > 0) {
            var occurrencesNotSolved = [];
            for (var i = 0; i < dr.occurrences.length; i++) {
                if (dr.occurrences[i].status === 'OCCURRENCE_TO_RESOLVE') {
                    occurrencesNotSolved.push(dr.occurrences[i]);
                }
            }
            if (occurrencesNotSolved.length > 0) {
                var s = ((occurrencesNotSolved.length === 1) ? '' : 's');
                if (occurrencesNotSolved.some(function (el) {
                    var d = new Date(el.createdAt);
                    return (now - d.getTime() > 2 * hora);
                })) {
                    // se alguma ocorrencia foi criada mais de 2h atrás
                    color = 'red';
                    tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
                }
                else {
                    // se alguma ocorrencia foi criada, e todas menos de 2h atrás
                    color = (color === 'red') ? color : 'yellow';
                    tooltip.push('há ' + occurrencesNotSolved.length + ' ocorrência' + s + ' pendente' + s);
                }
            }
        } // fim verificação ocorrencias
        dr['tooltip'] = tooltip;
        dr['color'] = color;
        dr['truckSaw'] = truckSaw;
        dr['novasMensagens'] = novasMensagens;
    };
    /********************
     * auxiliares a salvar buscas
     */
    CockpitComponent.prototype.saveSearches = function () {
        this.cookie.set(this.uid + 'chips', JSON.stringify(this.searchChips));
        this.cookie.set(this.uid + 'search_toggle', JSON.stringify(this.search_toggle));
    };
    CockpitComponent.prototype.loadSearches = function () {
        if (this.cookie.check(this.uid + 'chips')) {
            try {
                this.searchChips = JSON.parse(this.cookie.get(this.uid + 'chips'));
            }
            catch (ex) { }
        }
        if (this.cookie.check(this.uid + 'search_toggle')) {
            try {
                this.search_toggle = JSON.parse(this.cookie.get(this.uid + 'search_toggle'));
            }
            catch (ex) { }
        }
        this.applyFilter();
    };
    return CockpitComponent;
}());
export { CockpitComponent };
var MapDialogComponent = /** @class */ (function () {
    function MapDialogComponent(dialogRef, mdrService, router, snackBar, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.mdrService = mdrService;
        this.router = router;
        this.snackBar = snackBar;
        this.data = data;
        this.lat = 0;
        this.lng = 0;
        this.n = 0;
        this.list = [];
        this.subscriptions = [];
        var errors = 0;
        var mdrIdList = data.map(function (e) { return e.dr.idMDR; });
        this.subscriptions.push(mdrService.getLastPositions(mdrIdList).subscribe(function (result) {
            var _loop_1 = function (i, len) {
                var dataI = data.find(function (e) { return e.dr.idMDR === result[i].mdrId; });
                var marker_url = void 0;
                if (dataI.color === 'red') {
                    marker_url = './assets/images/red_truck.png';
                }
                else if (dataI.color === 'yellow') {
                    marker_url = './assets/images/blue_truck.png';
                }
                else {
                    marker_url = './assets/images/green_truck.png';
                }
                _this.list.push({
                    lat: result[i].lat,
                    lng: result[i].lng,
                    icon: {
                        url: marker_url,
                        scaledSize: {
                            width: 40,
                            height: 40
                        }
                    },
                    mdrId: result[i].mdrId
                });
            };
            for (var i = 0, len = result.length; i < len; i++) {
                _loop_1(i, len);
            }
            var latSUM = _this.list.map(function (e) { return e.lat; }).reduce(function (a, b) { return a + b; }, 0);
            _this.lat = latSUM / _this.list.length;
            var lngSUM = _this.list.map(function (e) { return e.lng; }).reduce(function (a, b) { return a + b; }, 0);
            _this.lng = lngSUM / _this.list.length;
            if (_this.list.length === 0) {
                _this.snackBar.open('Nenhuma localização disponível', 'Ok', { duration: 2000 });
                _this.dialogRef.close();
            }
        }, function (error) {
            _this.snackBar.open('Erro ao carregar localizações', 'Ok', { duration: 2000 });
            _this.dialogRef.close();
        }));
    }
    MapDialogComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    MapDialogComponent.prototype.goToMDR = function (point) {
        this.router.navigate(['logistic-order/' + point.mdrId]);
        this.dialogRef.close();
    };
    return MapDialogComponent;
}());
export { MapDialogComponent };
