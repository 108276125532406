<div class="grey-background">
  <div class="blue-background back-container">
  </div>
  <div class="row">
    <div class="white-background container-row center-container container-border col-md-10">
      <div class="row container-title">
        <div class="col-md-10">
          {{ 'DELIVERY_ROUTE/drs' | translate }} : {{ mdr.pickupID ? mdr.pickupID : (mdr.id | mdrId)}}
        </div>
        <div class="col-md-2 text-right">
          <i *ngIf="canEditDr" [matMenuTriggerFor]="editionMenu" matTooltip="Clique para gerenciar MDR" class="fa fa-plus mr-3"></i>
          <i *ngIf="role === 1 || role === 77"(click)="openExpedition()"  matTooltip="Laudo de Coleta"  class="fas fa-images mr-3"></i>
          <i (click)="listOVNF()"  matTooltip="Lista de OVs e NFs"  class="fas fa-list"></i>
        </div>
      </div>
      <mat-menu #editionMenu="matMenu">
        <button *ngIf="canAddDR" (click)="openDRAssociationDialog()" mat-menu-item > Adicionar DR a MDR </button>
        <button *ngIf="canMergeDR" (click)="openMergeMDRModal()" mat-menu-item> Mergear MDRs </button>
      </mat-menu>
      <hr>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef class="top"> {{ 'DELIVERY_ROUTE/'+column | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="column === 'deliveryAddress'"> <p><b>Entrega:</b> {{ element[column] }}</p><p><b>Coleta:</b> {{ element.pickupAddress }} </p> </div>
            <div *ngIf="column === 'site' || column === 'client'"> {{ element[column] }} </div>
            <div *ngIf="column === 'dateScheduling' || column === 'pickupDate' || column === 'arrivalTime'"> {{ element[column] | date:'d/M/yy, h:mm a'}} </div>
            <div *ngIf="column === 'status'"> {{ element.status | translate }} </div>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="container-fluid"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="expanded-columns column">

                <!-- content -->
                <div class="row justify-content-around">
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/drs' | translate }}: </span> {{ element.idDRidentifier }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/name_receptor' | translate }}: </span> {{ element.receiverName }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/receiver_phone' | translate }}: </span> {{ element.receiverPhone }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/name_supervisor' | translate }}: </span> {{ element.supervisorName }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/supervisor_phone' | translate }}: </span> {{ element.supervisorPhone }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/site_report' | translate }}: </span> {{ element.site_form6.join(', ') }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/infra' | translate }}: </span> {{ element.infraType }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/ov' | translate }}: </span> {{ element.salesOrders }} </div>
                </div>
                <div class="row justify-content-around">
                  <div class="col"> <span class="bold"> Obs: </span> {{ element.obs }} </div>
                  <div class="col"> <span class="bold"> {{ 'DELIVERY_ROUTE/pickupAddress' | translate }}: </span> {{ element.pickupAddress }} </div>
                  <div class="col" *ngIf="element.pickupDate"> <span class="bold"> {{ 'DELIVERY_ROUTE/pickupDate' | translate }}: </span> {{ element.pickupDate | date:'d/M/yy, h:mm a'}} </div>
                  <div class="col" *ngIf="element.pickupPhone"> <span class="bold"> {{ 'DELIVERY_ROUTE/pickupPhone' | translate }}: </span> {{ element.pickupPhone }} </div>
                  <div class="col" *ngIf="element.pickupSite"> <span class="bold"> {{ 'NEW-DELIVERY-REQUEST/text/pickup-site' | translate }}: </span> {{ element.pickupSite }} </div>
                </div>

                <!-- buttons -->
                <div class="row justify-content-end buttons-row">
                  <button *ngIf='role === 5 || role === 12 || role === 14 && element.userArrivalTime === null' (click)="setUserArrivalTime(element)" class="col-6 blue click-button">Chegou</button>
                  <button *ngIf='role === 1 || role === 5 && element.unificationId !== null' (click)="setCoronaNull(element)" class="col-6 blue click-button">Limpar TAG COVID-19</button>
                  <button *ngIf="role !== 3" (click)="openEditDialog(element)" class="col-6 blue click-button"> {{ 'DELIVERY_ROUTE/edit' | translate }} </button>
                  <button *ngIf="role !== 3" (click)="openViewDialog(element)" class="col blue click-button"> {{ 'DELIVERY_ROUTE/view' | translate }} </button>
                  <button *ngIf="role !== 3" (click)="openHistoryDialog(element)" class="col blue click-button"> {{ 'DELIVERY_ROUTE/history' | translate }} </button>
                </div>

              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="element-row click-button"
          [class.expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </div>
</div>

<ng-template #drAssociationModal>
  <div *ngIf="drsLoaded; else loadingSpinner" mat-dialog-content>
    <h1 mat-dialog-title>
      {{'Associação de DRs a MDR: ' + mdr.pickupID}}
    </h1>
    <p>
      Selecione uma ou mais DRs disponíveis para agendamento e confirme sua ação
    </p>

  <app-table
    [data]="drsFree"
    [columns]="drColumns"
    [this]="this"
    (check)="addToDRList($event)"
    showCheckbox = "true"
  ></app-table>

  <ng-container *ngIf="mdr.type === 'Cross Docking' && drsToAdd && drsToAdd.length">
    <div [ngStyle]="{'margin-bottom': '15px'}" mat-dialog-content>
      <div [ngStyle]="{
        'font-size': '16px',
        'margin-top': '3px',
        'margin-bottom': '16px',
        'font-weight': 'bold'
      }">
        Selecione o HUB de coleta para cada DR
      </div>
  
      <div *ngFor="let dr of drsToAdd">
        <div class="col-md-6">
          DR identifier: <span> {{dr.idDRidentifier}}</span>
        </div>
        <mat-form-field class="col-md-6">
          <mat-select  placeholder="Selecione o Hub" name="HubId" [(ngModel)]="dr.selectedHub">
            <mat-option *ngFor="let hub of hubList; index as i" [value]="hub.name"  >
              {{ hub.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</div>

  <div mat-dialog-actions class="d-flex justify-content-between w-100">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial >{{ 'Confirm' | translate }}</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial >{{ 'Cancel' | translate }}</button>
  </div>
</ng-template>

<ng-template #mergeMdrModal>
  <ng-container *ngIf="mdrsLoaded && avaibleMdrs.length; else notFound" mat-dialog-content>
    <h1 mat-dialog-title>
      {{ 'Merge MDRs' }}
    </h1>
    <p>
      Selecione uma ou mais MDRs que deseja adicionar a esta MDR. Somente as MDRs que possuem o mesmo Hub de origem e status "MDR em criação" estarão disponíveis no campo abaixo.
    </p>
    <mat-form-field class="col-md-6">
      <mat-select multiple required placeholder="Selecione MDRs..." [(ngModel)]="selectedMDRs">
        <mat-option  *ngFor="let mdr of avaibleMdrs" [value]="mdr.id">
          {{ mdr.pickupID }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <div mat-dialog-actions class="d-flex justify-content-between w-100">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial >{{ 'Confirm' | translate }}</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial >{{ 'Cancel' | translate }}</button>
  </div>

</ng-template>

<ng-template #loadingSpinner>
  <div class="row" *ngIf="!drsLoaded">
    <div class="col-md-12 text-center">
      <mat-progress-spinner *ngIf="!drsLoaded" diameter="24"></mat-progress-spinner>
      <p>Carregando DRs...</p>
    </div>
  </div>
</ng-template>

<ng-template #notFound>
  <div class="row">
    <div class="col-md-12 text-center">
      <p style="color: #d0d0d0; margin-top: 40px; font-size: 40px; text-align: center;"> Não existem MDRs disponíveis... </p>
    </div>
  </div>
</ng-template>
