<div class="search-field" fxLayout="column">

  <div class="simple" fxLayout="row" fxLayoutGap="10px">

    <button mat-icon-button
      (click)="toggleColumns()"
      matTooltip="{{ 'TABLE/BUTTON/TOGGLE_COLUMNS' | translate }}"
    >
      <mat-icon>remove_red_eye</mat-icon>
    </button>

    <button mat-icon-button
      (click)="toggleAdvancedSearch()"
      matTooltip="{{ 'TABLE/BUTTON/ADVANCED_SEARCH' | translate }}"
    >
      <mat-icon>tune</mat-icon>
    </button>

    <mat-form-field floatLabel="never">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chips"
          [disabled]="showAdvancedSearch"
          [selectable]="selectable"
          [removable]="removable && !showAdvancedSearch"
          (removed)="removeChip(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
        </mat-chip>
        <input placeholder="Digite para começar a filtrar os resultados por palavra chave..."
          [disabled]="showAdvancedSearch"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addChip($event)">
      </mat-chip-list>
    </mat-form-field>

    <mat-button-toggle-group
      #searchType="matButtonToggleGroup"
      (change)="applyFilter()"
    >
      <mat-button-toggle value="and">Todos</mat-button-toggle>
      <mat-button-toggle value="or">Alguns</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field floatLabel="never" fxFlex="25%"
      *ngIf="dateSearch.length">
      <input matInput
        placeholder="Limitar busca por data"
        [(ngModel)]="dates"
        (dateTimeChange)="applyFilter(true)"
        [selectMode]="'range'"
        [owlDateTimeTrigger]="rangeDatepicker"
        [owlDateTime]="rangeDatepicker">
      <owl-date-time #rangeDatepicker></owl-date-time>
    </mat-form-field>

    <button mat-icon-button
      *ngIf="downloadAs"
      (click)="download()"
      matTooltip="{{ 'TABLE/BUTTON/DOWNLOAD_XLSX' | translate }}"
    >
      <mat-icon>save_alt</mat-icon>
    </button>

  </div>

  <div class="advanced" *ngIf="showAdvancedSearch" fxLayout="column" fxLayoutGap="10px">

    <form #form="ngForm">
      <div *ngFor="let search of advancedSearchValues; index as i;"
        fxLayout="row"
        fxLayoutAlign="space-around center"
        fxLayoutGap="5px">
        <mat-form-field floatLabel="never">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let chip of search.chips"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="removeChipFromAdvanced(search.chips, chip)">
              {{chip.name}}
              <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
            </mat-chip>
            <input placeholder="Busca"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addChipFromAdvanced(search.chips, $event)">
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field fxFlex="35">
          <mat-label>Campo</mat-label>
          <mat-select [(ngModel)]="search.field" [name]="'field-'+i" required>
            <mat-option *ngFor="let column of columns" [value]="column.value">
              {{ column.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button (click)="removeSearchField(i)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </form>

    <button mat-button color="primary" (click)="addSearchField()">Adicionar campo</button>
    <button mat-flat-button color="primary" [disabled]="advancedSearchValues.length && !form.valid" (click)="applyFilter()">Realizar busca</button>

  </div>

</div>

<table mat-table [dataSource]="dataSource" matSort>

  <!-- Checkbox Column -->
  <ng-container [matColumnDef]="selectColumnName">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="onSelection($event)"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
        (change)="onSelection($event,row)"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Other Columns -->
  <ng-container *ngFor="let column of columns"
    [matColumnDef]="column.value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.name | translate}} </th>
    <td mat-cell *matCellDef="let row"
      [ngClass]="column.color ? column.color(row, parent) : ''"
      [matTooltip]="row[column.tooltip]"
      [matTooltipDisabled]="!column.tooltip"
      [ngSwitch]="typeof(row,column.value, column.type)"
    >
      <span *ngSwitchCase="'date'">
        {{ row[column.value] | date: 'd MMM y, HH:mm:ss' }}
      </span>
      <span *ngSwitchCase="'time'">
        {{ row[column.value] | date: 'shortTime' }}
      </span>
      <span *ngSwitchCase="'onlyDate'">
        {{ row[column.value] | date: 'dd/MM/yyyy' }}
      </span>
      <span *ngSwitchCase="'currency'">
        {{ row[column.value] | currency:rowCurrency(column.value) }}
      </span>
      <span *ngSwitchCase="'float'">
        {{ row[column.value].toFixed(decimalPoints) }}
      </span>
      <span *ngSwitchCase="'icon'">
        <mat-icon>{{row[column.icon]}}</mat-icon>
      </span>
      <span *ngSwitchDefault>
        {{ row[column.value] }}
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row
    [ngStyle]="{'cursor': (clickCallback ? 'pointer' : 'default') }"
    [ngClass]="style(row)"
    (click)="onClick(row)"
    *matRowDef="let row; columns: displayColumns;">
  </tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
