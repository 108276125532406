var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter } from '@angular/core';
import { MatSnackBar, MatMenuTrigger } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MDR, MDR_TYPE } from '../../../models/mdr';
import { UploadFileService } from '../../../providers/upload.service';
import { NfeService } from '../../../providers/nfe.service';
import { MdrService } from '../../../providers/mdr.service';
import { DrService } from '../../../providers/dr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OVService } from '../../../providers/ov.service';
import { saveAs } from 'file-saver/FileSaver';
import { MdrObservable } from '../../../services/mdr.service';
import { UOCObservable } from '../../../services/uoc.service';
import * as JSZip from 'jszip';
import { forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HubService } from '../../../providers/hub.service';
var AttachmentsComponent = /** @class */ (function () {
    function AttachmentsComponent(ovService, uploadService, sanitizer, mdrService, drService, snackBar, dialog, nfeservice, uocObservable, mdrObservable, hubService) {
        this.ovService = ovService;
        this.uploadService = uploadService;
        this.sanitizer = sanitizer;
        this.mdrService = mdrService;
        this.drService = drService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.nfeservice = nfeservice;
        this.uocObservable = uocObservable;
        this.mdrObservable = mdrObservable;
        this.hubService = hubService;
        this.updateValue = new EventEmitter();
        this.nfFiles = [];
        this.rgAux = '';
        this.nameAux = '';
        this.dateAux = '';
        this.hourAux = '';
        this.podFiles = 0;
        this.uocList = [];
        this.uocFiles = [];
        this.disablePODEditing = false;
        this.sendStatus = false;
        this.statusContainer = false;
        this.fakeUoc = 'Anexo de Confirmação';
        this.savePod = false;
        this.podColumns = [
            { value: 'referenceNf', name: 'Nota Fiscal' },
            { value: 'name', name: 'Nome' },
            { value: 'rg', name: 'RG' },
            { value: 'deliveredAt', name: 'Data da Entrega', type: 'onlyDate' },
            { value: 'timeDeliveredAt', name: 'Hora da Entrega', type: 'time' },
        ];
        this.podData = [];
        this.podsToUpdate = [];
        this.canSavePOD = false;
        this.PODS_BLOCKED_STATUS = [
            'Entrega de carga certificada pelo LSP',
            'Entrega certificada-LSP',
            'MDR paga / concluída',
            'MDR paid / completed'
        ];
        this.templatePODData = {
            name: null,
            rg: null,
            deliveredAt: null,
            timeDeliveredAt: null
        };
        this.allPODsCanBeEdited = false;
        this.podsCanBeRemoved = false;
        // se existe uma NF que foi emitida a mais de 30 dias
        this.existeNfVermelha = false;
        this.today = new Date();
        // contagem de NFs e PODs
        this.uniqueCount = {
            relation: null,
            nfs: null,
            pods: null,
        };
        // relations ausentes
        this.missingFiles = [];
        // novo modo de pegar anexos
        this.manuallyUploadedFiles = [];
        this.invoiceFiles = [];
        this.proofOfDeliveryFiles = [];
        this.podList = {
            nfs: [],
            ovs: [],
            cte: null
        };
        // pra icone de ha quanto tempo os PODs foram emitidos
        this.icons = {
            'green': 'fas fa-check-circle',
            'yellow': 'fas fa-exclamation-triangle',
            'red': 'fas fa-times-circle',
            'undefined': 'fas fa-hourglass-half',
        };
        this.tooltips = {
            'green': 'Nota Fiscal emitida menos de 15 dias atrás',
            'yellow': 'Nota Fiscal emitida entre 15 e 30 dias atrás',
            'red': 'Nota Fiscal emitida mais de 30 dias atrás',
            'undefined': 'Sem data de emissão da Nota Fiscal',
        };
        this.user = JSON.parse(localStorage.getItem('currentUser')).user;
        this.nfList = [];
        this.ngOnInit();
    }
    AttachmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        // ao trocar a MDR
        this.mdrObservable.currentMDR.subscribe(function (mdr) {
            _this.mdr = mdr;
            var PODS_BLOCKED_STATUS = [
                'Entrega de carga certificada pelo LSP',
                'Entrega certificada-LSP',
                'MDR paga / concluída',
                'MDR paid / completed'
            ];
            _this.disablePODEditing = PODS_BLOCKED_STATUS.includes(_this.mdr['status']);
            _this.updateNF();
        });
        // ao trocarem os UOCs
        this.uocObservable.currentUOC.subscribe(function (data) {
            _this.uocList = data.filter(function (e) { return e.needsAttachment && e.uoc !== _this.fakeUoc; }) || [];
            _this.updateUOCFiles();
        });
    };
    AttachmentsComponent.prototype.receiveFile = function (event) {
        this.savePod = true;
        this.updateNF();
        this.updateUOCFiles();
    };
    AttachmentsComponent.prototype.updateInfo = function () {
        var _this = this;
        this.nfList.forEach(function (nf) {
            if (typeof nf['file'] !== 'undefined' && nf['protected'] === false) {
                nf['file'].forEach(function (file) {
                    _this.uploadService.updateFileInfo(_this.mdr.id, file).subscribe(function (data) {
                        // console.log(data);
                    });
                }, _this);
            }
        }, this);
    };
    AttachmentsComponent.prototype.removeFile = function (file, entity) {
        var _this = this;
        this.uploadService.deleteByEntity(file['id'], entity).subscribe(function (response) {
            _this.updateNF();
            _this.updateUOCFiles();
        });
    };
    AttachmentsComponent.prototype.savePOD = function (nf, ans) {
        var _this = this;
        if (ans === void 0) { ans = null; }
        var cteInfo = [
            {
                rg: nf.fileInfo.rg,
                name: nf.fileInfo.name,
                deliveredAt: nf.fileInfo.deliveredAt,
                timeDeliveredAt: nf.fileInfo.timeDeliveredAt,
                idFile: nf.id
            }
        ];
        this.uploadService.updatePODInfo(this.mdr.id, cteInfo).subscribe(function (response) {
            if (ans) {
                _this.podList.cte = ans;
            }
            _this.snackBar.open('Dados do Recebedor atualizados', 'Fechar', {
                duration: 4000,
            });
        });
    };
    AttachmentsComponent.prototype.allPODsUploaded = function () {
        for (var i = 0; i < this.uocList.length; i++) {
            if (this.uocList[i].files.length === 0) {
                return false;
            }
        }
        for (var i = 0; i < this.nfList.length; i++) {
            if (this.nfList[i].dr === this.nfList[i].drSelected) {
                if (this.nfList[i].file === undefined) {
                    return false;
                }
            }
        }
        var neededPods = this.podList.nfs.length + this.podList.ovs.length;
        var allPods = [].concat.apply([], this.podList.nfs.map(function (nf) { return nf.files; }).concat(this.podList.ovs.map(function (ov) { return ov.files; })));
        if (this.podList.cte !== undefined && this.podList.cte !== null) {
            neededPods += this.podList.cte.length;
            allPods = allPods.concat(this.podList.cte);
        }
        var flag = true;
        this.podList.ovs.forEach(function (el) {
            // console.log(el, el.files.length);
            if (el.files.length === 0) {
                flag = false;
            }
        });
        if (!flag) {
            return false;
        }
        if (this.uniqueCount.pods > neededPods) {
            return false;
        }
        // check that all PODs contain the needed information
        var missingInformation = false;
        allPods.forEach(function (pod) {
            if (pod.fileInfo.name === null || pod.fileInfo.rg === null || pod.fileInfo.deliveredAt === null || pod.fileInfo.timeDeliveredAt === null) {
                missingInformation = true;
            }
        });
        if (missingInformation) {
            return false;
        }
        // all is good
        return true;
    };
    AttachmentsComponent.prototype.updateNF = function (updateMDR) {
        var _this = this;
        if (updateMDR === void 0) { updateMDR = false; }
        // busca arquivos subidos manualmente
        this.ovService.getFilesFromMdrManually(this.mdr.id).subscribe(function (response) {
            var files = response;
            files.mdrFiles.forEach(function (file) {
                file.type = 'mdr';
            });
            files.drFiles.forEach(function (file) {
                file.type = 'dr';
            });
            _this.manuallyUploadedFiles = files.mdrFiles.concat(files.drFiles);
        });
        // busca anexos de OVs e NFs de terceiros
        // busca tambem relations
        forkJoin({
            ovs: this.ovService.getFilesFromMdrByOv(this.mdr.id),
            nfs: this.ovService.getFilesFromMdrByNf(this.mdr.id),
            relations: this.ovService.getNfRelationsByMdr(this.mdr.id),
        }).subscribe(function (response) {
            // console.log({ovs: response.ovs, nfs: response.nfs});
            // conta relations
            _this.uniqueCount.relation = response.relations.ovs.length + response.relations.nfs.length;
            _this.invoiceFiles = [].concat(response.ovs, response.nfs)
                .filter(function (value, index, self) { return value != null && self.map(function (file) { return file ? file.fileEntity.idEntity : null; }).indexOf(value.fileEntity.idEntity) === index; });
            // console.log({invoices: this.invoiceFiles});
            // conta OVs e NFs únicas
            var existingFiles = _this.invoiceFiles
                .map(function (file) { return file.fileEntity.idEntity; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; });
            _this.uniqueCount.nfs = existingFiles.length;
            // salva quais relations estão ausentes
            _this.missingFiles = [].concat(response.relations.ovs, response.relations.nfs)
                .filter(function (value) {
                return (existingFiles.indexOf(value) === -1 &&
                    existingFiles.indexOf(value.replace(/^[0]*/, '').replace(/[-].*/, '')) === -1);
            });
            _this.totalNfCost = 0;
            _this.invoiceFiles
                .forEach(function (file) {
                if (file.fileEntity.entity === 'nfe_xml') {
                    _this.totalNfCost += parseFloat(file.nfe_xml.totalValue);
                }
                else if (file.fileEntity.entity === 'NFe') {
                    _this.totalNfCost += parseFloat(file.nfe.totalValue);
                }
            });
            _this.updateValue.next(updateMDR);
        });
        // busca PODs de OVs e NFs de terceiros
        forkJoin({
            ovs: this.ovService.getFilesFromMdrByOvForPOD(this.mdr.id),
            nfs: this.ovService.getFilesFromMdrByNfForPOD(this.mdr.id),
        }).subscribe(function (response) {
            // console.log({response: response});
            // se nao possui nenhum dos dois
            if (response.ovs === null && response.nfs === null) {
                // busca PODs de CTE
                _this.ovService.getFilesFromMdrByCteForPOD(_this.mdr.id).subscribe(function (ans) {
                    if (ans) {
                        if (_this.mdr.type === 'Cross Docking' && _this.mdr.SAD !== '' && _this.savePod === true) {
                            for (var _i = 0, ans_1 = ans; _i < ans_1.length; _i++) {
                                var elem = ans_1[_i];
                                if (elem.fileInfo.name === null && elem.fileInfo.rg === null && elem.fileInfo.deliveredAt === null && elem.fileInfo.timeDeliveredAt === null) {
                                    elem.fileInfo.name = "HUB-" + _this.mdr.shippingCompany;
                                    elem.fileInfo.rg = "HUB-" + _this.mdr.shippingCompany;
                                    elem.fileInfo.deliveredAt = elem.fileEntity.createdAt;
                                    elem.fileInfo.timeDeliveredAt = elem.fileEntity.createdAt;
                                    _this.savePOD(elem, ans);
                                    break;
                                }
                            }
                        }
                        _this.podList.cte = ans;
                    }
                });
                // pra CTE é sempre só 1 POD
                _this.uniqueCount.pods = 1;
                return;
            }
            var now = new Date().getTime();
            // calcula tempo desde a emissão da nota
            function getColor(item) {
                // sem data de emissão
                if (!item.emissionDate) {
                    item['color'] = 'undefined';
                    return item;
                }
                var emissionDate = new Date(item.emissionDate).getTime();
                if ((now - emissionDate) <= (15 * 24 * 60 * 60 * 1000)) {
                    // menos de 15 dias
                    item['color'] = 'green';
                }
                else if ((15 * 24 * 60 * 60 * 1000) < (now - emissionDate)
                    && (now - emissionDate) <= (30 * 24 * 60 * 60 * 1000)) {
                    // entre 15 e 30 dias
                    item['color'] = 'yellow';
                }
                else if ((30 * 24 * 60 * 60 * 1000) < (now - emissionDate)) {
                    // mais de 30 dias
                    item['color'] = 'red';
                }
                return item;
            }
            if (response.ovs !== null) {
                _this.podList.ovs = response.ovs.map(getColor);
            }
            if (response.nfs !== null) {
                _this.podList.nfs = response.nfs.map(getColor);
            }
            // console.log({podList: this.podList});
            // conta PODs de OVs e NFs únicas
            _this.uniqueCount.pods =
                _this.podList.ovs
                    .map(function (ov) { return ov.referenceNoNf; })
                    .filter(function (value, index, self) { return self.indexOf(value) === index; })
                    .length +
                    _this.podList.nfs
                        .map(function (nf) { return nf.invoiceNumber; })
                        .filter(function (value, index, self) { return self.indexOf(value) === index; })
                        .length;
        });
    };
    AttachmentsComponent.prototype.updateUOCFiles = function () {
        var _this = this;
        // atualiza anexos dos UOCs
        this.uocList.forEach(function (uoc, i, arr) {
            // busca os arquivos associados
            _this.mdrService.getUOCWithInfo(uoc.id).subscribe(function (response) {
                uoc['files'] = response;
            });
        });
    };
    AttachmentsComponent.prototype.copyData = function (file) {
        this.rgAux = file.rg;
        this.nameAux = file.name;
        this.dateAux = file.deliveredAt;
        this.hourAux = file.timeDeliveredAt;
    };
    AttachmentsComponent.prototype.pasteData = function (file) {
        file.rg = this.rgAux;
        file.name = this.nameAux;
        file.deliveredAt = this.dateAux;
        file.timeDeliveredAt = this.hourAux;
    };
    AttachmentsComponent.prototype.dowloadAll = function () {
        return __awaiter(this, void 0, void 0, function () {
            var zip, img, nffiles, uploadService, _loop_1, _i, nffiles_1, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        zip = new JSZip();
                        img = zip.folder('images');
                        nffiles = this.invoiceFiles;
                        uploadService = this.uploadService;
                        _loop_1 = function (i) {
                            var image, url;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        image = i;
                                        url = i.path;
                                        return [4 /*yield*/, uploadService.getImage(url).subscribe(function (result) {
                                                var reader = new FileReader();
                                                reader.readAsText(result);
                                                reader.onloadend = function () {
                                                    var imgData = reader.result;
                                                    img.file(image['fileOriginalName'], result, { base64: true });
                                                    if (i === nffiles[nffiles.length - 1]) {
                                                        zip.generateAsync({ type: 'blob' })
                                                            .then(function (content) {
                                                            // see FileSaver.js
                                                            saveAs(content, 'notas_fiscais.zip');
                                                        });
                                                    }
                                                };
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _i = 0, nffiles_1 = nffiles;
                        _a.label = 1;
                    case 1:
                        if (!(_i < nffiles_1.length)) return [3 /*break*/, 4];
                        i = nffiles_1[_i];
                        return [5 /*yield**/, _loop_1(i)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AttachmentsComponent.prototype.removeNfs = function (toReturn) {
        var _this = this;
        var isHubReturn = toReturn;
        // busca por drId
        this.ovService.getNfNotRelationsByMdr(this.mdr.id).subscribe(function (InvoiceResponse) {
            // abre dialog
            _this.dialog.open(RemoveNfsDialogComponent, {
                data: {
                    invoices: InvoiceResponse,
                    isHubReturn: isHubReturn
                }
            }).afterClosed().subscribe(function (_a) {
                var toRemove = _a.toRemove, selectedHub = _a.selectedHub;
                if (isHubReturn === true && selectedHub !== null) {
                    _this.ovService.sendHubReturn(toRemove.map(function (e) { return e.refNo; }), selectedHub).subscribe(function (resp) {
                        _this.snackBar.open('Notas fiscais desassociadas com sucesso', 'Ok', { duration: 4000 });
                        _this.updateNF(true);
                    }, function (error) {
                        _this.snackBar.open('Erro ao desassociar notas fiscais', 'Ok', { duration: 4000 });
                    });
                }
                else if (isHubReturn === false && !selectedHub) {
                    // envia pra remoção
                    var body = {
                        ovs: toRemove.filter(function (r) { return r.ov !== 'Nota Fiscal Terceiro'; }).map(function (nf) { return nf.refNo; }),
                        nfs: toRemove.filter(function (r) { return r.ov === 'Nota Fiscal Terceiro'; }).map(function (nf) { return nf.refNo; })
                    };
                    _this.ovService.removeFromMDR(_this.mdr.id, body).subscribe(function (response) {
                        // remoção feita com sucesso
                        _this.snackBar.open('Notas fiscais desassociadas com sucesso', 'Ok', { duration: 4000 });
                        _this.updateNF(true);
                    }, function (err) {
                        // erro ao remover
                        _this.snackBar.open('Erro ao desassociar notas fiscais', 'Ok', { duration: 4000 });
                    });
                }
            });
        });
    };
    AttachmentsComponent.prototype.addNfs = function () {
        var _this = this;
        this.drService.getByMdr(this.mdr.id).subscribe(function (drList) {
            _this.dialog.open(AddNfsDialogComponent, {
                width: '60vw',
                data: {
                    drList: drList,
                }
            }).afterClosed().subscribe(function (response) {
                // envia pra adição
                _this.ovService.addToDR(_this.mdr.id, response.dr.id, response.toAdd).subscribe(function () {
                    // adição feita com sucesso
                    _this.snackBar.open('Notas fiscais associadas com sucesso', 'Ok', { duration: 4000 });
                    _this.updateNF(true);
                }, function (err) {
                    // erro ao adicionar
                    _this.snackBar.open('Erro ao associar notas fiscais', 'Ok', { duration: 4000 });
                });
            });
        });
    };
    AttachmentsComponent.prototype.openPODInfoModal = function (templateModal) {
        var _this = this;
        var mergedPODs;
        var disabledByStatus = this.PODS_BLOCKED_STATUS.includes(this.mdr.status);
        if (this.podList.ovs.length || this.podList.nfs.length) {
            mergedPODs = this.podList.ovs.concat(this.podList.nfs);
            for (var _i = 0, mergedPODs_1 = mergedPODs; _i < mergedPODs_1.length; _i++) {
                var pod = mergedPODs_1[_i];
                var invoiceReference = (pod.referenceNoNf && pod.salesDocument) ? pod.salesDocument + " - " + pod.referenceNoNf : "THIRDPARTY - " + pod.invoiceNumber;
                for (var _a = 0, _b = pod.files; _a < _b.length; _a++) {
                    var file = _b[_a];
                    this.podData.push({
                        referenceNf: invoiceReference,
                        name: file.fileInfo && file.fileInfo.name ? file.fileInfo.name : null,
                        rg: file.fileInfo && file.fileInfo.rg ? file.fileInfo.rg : null,
                        deliveredAt: file.fileInfo && file.fileInfo.deliveredAt ? file.fileInfo.deliveredAt : null,
                        timeDeliveredAt: file.fileInfo && file.fileInfo.timeDeliveredAt ? file.fileInfo.timeDeliveredAt : null,
                        file: file.path,
                        idFile: file.id,
                        disabled: invoiceReference.startsWith('THIRDPARTY') ? false : disabledByStatus,
                        removable: invoiceReference.startsWith('THIRDPARTY') ? true : !disabledByStatus
                    });
                }
            }
        }
        else if (this.podList.cte.length) {
            var invoiceRef = "HUB - " + this.mdr.id;
            for (var _c = 0, _d = this.podList.cte; _c < _d.length; _c++) {
                var cte = _d[_c];
                this.podData.push({
                    referenceNf: invoiceRef,
                    name: cte.fileInfo && cte.fileInfo.name ? cte.fileInfo.name : null,
                    rg: cte.fileInfo && cte.fileInfo.rg ? cte.fileInfo.rg : null,
                    deliveredAt: cte.fileInfo && cte.fileInfo.deliveredAt ? cte.fileInfo.deliveredAt : null,
                    timeDeliveredAt: cte.fileInfo && cte.fileInfo.timeDeliveredAt ? cte.fileInfo.timeDeliveredAt : null,
                    file: cte.path,
                    idFile: cte.id,
                    disabled: (this.mdr.type === MDR_TYPE.CROSS_DOCKING) ? true : disabledByStatus,
                    removable: true
                });
            }
        }
        var podDialog = this.dialog.open(templateModal, {
            height: '90%',
            width: '80%',
            data: {
                podData: this.podData,
            }
        });
        podDialog.afterClosed().subscribe(function (dialogResponse) {
            if (dialogResponse === true) {
                _this.updatePOD();
                console.log(_this.podsToUpdate);
            }
            _this.resetPodModal();
        });
    };
    AttachmentsComponent.prototype.updatePOD = function () {
        var _this = this;
        var body = this.podsToUpdate.map(function (pod) { return ({
            name: pod.name,
            rg: pod.rg,
            deliveredAt: pod.deliveredAt,
            timeDeliveredAt: pod.timeDeliveredAt,
            idFile: pod.idFile
        }); });
        if (Object.values(body).some(function (info) { return !info || info === null; })) {
            this.snackBar.open('Existem campos sem preenchimento. Por favor, tente novamente.', 'Ok', { duration: 4000 });
            return;
        }
        this.uploadService.updatePODInfo(this.mdr.id, body).subscribe(function (response) {
            if (response) {
                _this.updateNF();
                _this.snackBar.open('PODs atualizados com sucesso', 'Ok', { duration: 4000 });
            }
        });
    };
    AttachmentsComponent.prototype.setPODsEditing = function (selectedPODs) {
        var uniquePODs = Array.from(new Set(selectedPODs.slice()));
        this.podsToUpdate = uniquePODs.map(function (pod) { return ({
            name: pod.name || null,
            rg: pod.rg || null,
            idFile: pod.idFile,
            deliveredAt: pod.deliveredAt || null,
            timeDeliveredAt: pod.timeDeliveredAt || null,
            reference: pod.referenceNf,
            disabled: pod.disabled,
            removable: pod.removable
        }); });
        this.allPODsCanBeEdited = this.podsToUpdate.every(function (pod) { return pod.disabled === false; });
        this.podsCanBeRemoved = this.podsToUpdate.every(function (pod) { return pod.removable === true; });
    };
    AttachmentsComponent.prototype.copyForAll = function () {
        var firstValue = {
            name: this.templatePODData.name,
            rg: this.templatePODData.rg,
            deliveredAt: this.templatePODData.deliveredAt,
            timeDeliveredAt: this.templatePODData.timeDeliveredAt
        };
        for (var _i = 0, _a = this.podsToUpdate; _i < _a.length; _i++) {
            var pod = _a[_i];
            pod.name = firstValue.name;
            pod.rg = firstValue.rg;
            pod.deliveredAt = firstValue.deliveredAt;
            pod.timeDeliveredAt = firstValue.timeDeliveredAt;
        }
    };
    AttachmentsComponent.prototype.openPODTemplateModal = function () {
        var _this = this;
        var fillPodDialog = this.dialog.open(this.PODTemplate, {
            height: '30%',
            width: '75%',
            data: {}
        });
        fillPodDialog.afterClosed().subscribe(function (dialogResponse) {
            if (dialogResponse === true) {
                _this.copyForAll();
            }
            _this.templatePODData = {
                name: null,
                rg: null,
                deliveredAt: null,
                timeDeliveredAt: null
            };
        });
    };
    AttachmentsComponent.prototype.resetPodModal = function () {
        this.podsToUpdate = [];
        this.podData = [];
    };
    AttachmentsComponent.prototype.deleteFiles = function () {
        var _this = this;
        var deleteConfirmation = this.dialog.open(this.ConfirmRemoval, {
            height: '30%',
            width: '50%',
            data: {}
        });
        deleteConfirmation.afterClosed().subscribe(function (dialogResponse) {
            if (dialogResponse === true) {
                var idsToRemove_1 = _this.podsToUpdate.map(function (pod) { return pod.idFile; });
                _this.uploadService.deleteMany(idsToRemove_1).subscribe(function (response) {
                    if (response) {
                        _this.snackBar.open('PODs deletados com sucesso', 'Ok', { duration: 5000 });
                        _this.podData = _this.podData.filter(function (el) { return !idsToRemove_1.includes(el.idFile); });
                        _this.podsToUpdate = _this.podsToUpdate.filter(function (el) { return !idsToRemove_1.includes(el.idFile); });
                        _this.updateNF();
                    }
                    else {
                        _this.snackBar.open('Não foi possível deletar os PODs. Por favor, tente novamente', 'Ok', { duration: 5000 });
                    }
                });
            }
        });
    };
    return AttachmentsComponent;
}());
export { AttachmentsComponent };
var RemoveNfsDialogComponent = /** @class */ (function () {
    function RemoveNfsDialogComponent(data, dialogRef, hubService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.hubService = hubService;
        this.toRemove = [];
        this.columns = [
            { value: 'ov', name: 'OV' },
            { value: 'refNo', name: 'Nota Fiscal' },
        ];
        this.valid = true;
        // refers to dialog
        this.title = '';
        this.description = '';
        // refers to dialog header if is hubReturn
        this.returnModalInfo = {
            titleInfo: 'Enviar Nota para Hub',
            descriptionInfo: 'Selecione a nota que deseja desassociar e o HUB que deseja enviar a nota. Após isso, uma nova DR com status HubReturn será criada com a nota que foi desassociada.'
        };
        // refers to dialog header if is not hub return
        this.notReturnModalInfo = {
            titleInfo: 'Desassociar Nota Fiscal',
            descriptionInfo: 'Selecione a nota que deseja desassociar da MDR, essa nota ficará disponível para outro Agendamento.'
        };
    }
    RemoveNfsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        // setting modal title and description
        this.existing = this.data.invoices.nfes.map(function (nf) { return ({ ov: 'Nota Fiscal Terceiro', refNo: nf }); }).concat(this.data.invoices.zvs);
        if (this.data.isHubReturn === true) {
            this.title = this.returnModalInfo.titleInfo;
            this.description = this.returnModalInfo.descriptionInfo;
            // get hubs
            this.hubService.getAll(this.valid).subscribe(function (hubsResponse) {
                if (hubsResponse) {
                    _this.hubList = hubsResponse;
                }
            });
        }
        else {
            this.title = this.notReturnModalInfo.titleInfo;
            this.description = this.notReturnModalInfo.descriptionInfo;
        }
    };
    RemoveNfsDialogComponent.prototype.submit = function () {
        this.dialogRef.close({
            toRemove: this.toRemove,
            selectedHub: this.selectedHub
        });
    };
    RemoveNfsDialogComponent.prototype.addToList = function (checkedItems) {
        this.toRemove = checkedItems;
    };
    return RemoveNfsDialogComponent;
}());
export { RemoveNfsDialogComponent };
var AddNfsDialogComponent = /** @class */ (function () {
    function AddNfsDialogComponent(data, dialogRef, ovService, nfService, snackBar) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.ovService = ovService;
        this.nfService = nfService;
        this.snackBar = snackBar;
        this.toAdd = [];
        this.columns = [
            { value: 'column1', name: 'MDR' },
            { value: 'column2', name: 'Sales Order' },
            { value: 'column3', name: 'Invoice' },
            { value: 'column4', name: 'Client' },
            { value: 'column5', name: 'Equipment' },
        ];
        this.searchedValues = [];
        this.drList = [];
        this.selectedDr = null;
        this.drList = this.data.drList;
    }
    AddNfsDialogComponent.prototype.search = function () {
        var _this = this;
        if (this.searchInput === '') {
            return;
        }
        // search OVs and NFs
        forkJoin({
            ovs: this.ovService.getAll(this.searchInput).pipe(map(function (res) { return res; }), catchError(function (e) { return of([]); })),
            nfs: this.nfService.getAll(this.searchInput).pipe(map(function (res) { return res; }), catchError(function (e) { return of(null); }))
        }).subscribe(function (response) {
            // add types to difference between the 2
            // and extract the info to show on the table
            var data = [];
            if (response.ovs.length > 0) {
                data = [].concat(data, response.ovs.map(function (ov) {
                    return Object.assign(ov, {
                        type: 'zvsd1',
                        column1: (!ov.mdr ? null : (ov.mdr.pickupID ? ov.mdr.pickupID : 'EDB-TP' + ('000000' + ov.mdr.id).slice(-6))),
                        column2: ov.salesDocument,
                        column3: ov.referenceNoNf,
                        column4: ov.customerName,
                        column5: ov.materialDescription,
                    });
                }));
            }
            if (response.nfs !== undefined && response.nfs !== null) {
                data = [].concat(data, [response.nfs].map(function (nf) {
                    return Object.assign(nf, {
                        type: 'nfe',
                        column1: (!nf.mdr ? null : (nf.mdr.pickupID ? nf.mdr.pickupID : 'EDB-TP' + ('000000' + nf.mdr.id).slice(-6))),
                        column2: null,
                        column3: nf.invoiceNumber,
                        column4: nf.client,
                        column5: nf.materials,
                    });
                }));
            }
            // still no results
            if (!data || data.length === 0) {
                _this.snackBar.open('NF/OV já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
                return;
            }
            // extract the values to show and add to table
            _this.searchedValues = data.map(function (row) {
                return Object.assign(row, {});
            });
        }, function (err) {
            _this.snackBar.open('NF/OV já utilizada ou fora do sistema', 'Ok', { duration: 4000 });
        });
    };
    AddNfsDialogComponent.prototype.rowStyle = function (row) {
        if (!!row.drId) {
            return 'grey';
        }
        return null;
    };
    AddNfsDialogComponent.prototype.addToList = function (checkedItems) {
        // nao adiciona se tiver drId!=null
        this.toAdd = checkedItems.filter(function (row) { return row.drId === null; });
    };
    AddNfsDialogComponent.prototype.removeFromList = function (rowToBeRemoved) {
        this.toAdd = this.toAdd.filter(function (row) {
            return row.type === rowToBeRemoved.type && row.id !== rowToBeRemoved.id;
        });
    };
    AddNfsDialogComponent.prototype.submit = function () {
        this.dialogRef.close({
            dr: this.selectedDr,
            toAdd: this.toAdd,
        });
    };
    return AddNfsDialogComponent;
}());
export { AddNfsDialogComponent };
